.header {
  padding: 28px 0 0;
  position: relative;
  &__wrp {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__mob {
    width: fit-content;
    text-align: right;
    margin-right: 0;
    p {
      color: #3B5171;
      font-weight: 300;
      font-size: 18px;
      text-align: left;
    }
    img {
      margin-right: 10px;
    }
    a {
      color: #3B5171;
      font-weight: 300;
      font-size: 18px;
      &:hover,
      &:focus {
        color: #3B5171;
      }
    }
  }
  &__logo {
    width: 11%;
  }
  &__mobLink {
    color: #3B5171;
    font-weight: 200;
    font-size: 20px;
    font-family: $font-family-base;
    span {
      font-weight: 300;
    }
  }
  &__mobCity {
    display: none;
    width: fit-content;
    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    img {
      margin-left: 5px;
    }
  }
  &__searchWrap {
    width: 100%;
    margin: 26.5px auto 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #3B5171;
    padding: 25px 59.5px 29px 140px;
    display: none;
  }
  &__sideName {
    color: #fff;
    font-weight: 400;
    font-size: 26px;
    font-family: $font-family-base;
    width: 79%;
  }
  &__form {
    position: relative;
    width: 65%;
    display: none;
  }
  &__input {
    width: 100%;
    background: #fff;
    height: 50px;
    padding: 0 30px;
    font-weight: 300;
    color: #7F7F7F;
    font-size: 20px;
    font-family: $font-family-base;
  }
  &__send {
    position: absolute;
    background: #FFC400 url(../images/search.png);
    background-repeat: no-repeat;
    background-position: center;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    font-size: 0;
  }
  &__cityName {
    color: #fff;
    font-weight: 400;
    font-size: 20px;
    font-family: $font-family-base;
    margin: 0;
  }
  &__burger {
    background: transparent;
    display: none;
    padding: 0;
    div {
      width: 20px;
      height: 3px;
      background: #3B5171;
      transition: 0.5s;
      &:not(:last-of-type) {
        margin-bottom: 4px;
      }
    }
    &--anim {
      div {
        background: #FFC400;
        transition: 0.5s;
        &:first-of-type {
          transform: translate3d(-1px, 7px, 0) rotate(45deg);
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:last-of-type {
          transform: translate3d(0px, -7px, 0) rotate(-45deg);
        }
      }
    }
  }
}

/*1600*/

@media screen and (min-width: 1501px) and (max-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .header {
    &__sideName {
      font-size: 24px;
    }
    &__city {}
    &__form {
      width: 66%;
    }
    &__input {
      font-size: 18px;
      height: 45px;
    }
    &__send {
      height: 45px;
    }
    &__cityName {
      font-size: 18px;
    }
    &__mobLink {
      font-size: 18px;
    }
  }
}

/*1440*/

@media screen and (min-width: 1401px) and (max-width: 1500px) and (-webkit-min-device-pixel-ratio: 1) {
  .header {
    &__wrp {
      width: 85%;
    }
    &__sideName {
      font-size: 22px;
    }
    &__form {
      width: 69.7%;
    }
    &__input {
      font-size: 16px;
      height: 40px;
    }
    &__send {
      height: 40px;
    }
    &__cityName {
      font-size: 16px;
    }
    &__mobLink {
      font-size: 16px;
    }
  }
}

/*1366*/

@media screen and (min-width: 1301px) and (max-width: 1400px) and (-webkit-min-device-pixel-ratio: 1) {
  .header {
    &__sideName {
      font-size: 20px;
    }
    &__wrp {
      width: 90%;
    }
    &__form {
      width: 69.7%;
    }
    &__input {
      font-size: 16px;
      height: 40px;
    }
    &__send {
      height: 40px;
    }
    &__cityName {
      font-size: 16px;
    }
    &__mobLink {
      font-size: 16px;
    }
  }
}

/*1280*/

@media screen and (min-width: 1200px) and (max-width: 1300px) and (-webkit-min-device-pixel-ratio: 1) {
  .header {
    &__sideName {
      font-size: 18px;
    }
    &__searchWrap {
      padding: 25px 59.5px 29px 135px;
    }
    &__form {
      width: 69.7%;
    }
    &__input {
      font-size: 16px;
      height: 40px;
    }
    &__send {
      height: 40px;
    }
    &__cityName {
      font-size: 16px;
    }
    &__mobLink {
      font-size: 16px;
    }
    &__wrp {
      width: 92%;
    }
  }
}

/*1024*/

@media screen and (min-width: 900px) and (max-width: 1199px) and (-webkit-min-device-pixel-ratio: 1) {
  .header {
    &__burger {
      display: block;
    }
    &__sideName {
      display: none;
    }
    &__wrp {
      display: block;
      width: 100%;
    }
    &__mobCity {
      display: block;
      &:first-of-type {
        margin-right: -55%;
      }
    }
    &__searchWrap {
      padding: 25px 100px 29px;
      margin-top: 0;
    }
    &__logo {
      width: 100%;
      padding: 0 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__city {
      display: none;
    }
    &__form {
      width: 90%;
    }
    &__input {
      font-size: 16px;
      height: 40px;
    }
    &__send {
      height: 40px;
    }
    &__cityName {
      font-size: 16px;
      color: #3B5171;
    }
    &__mobLink {
      font-size: 16px;
    }
    &__mob {
      display: none;
    }
  }
}

/*800*/

@media screen and (min-width: 799px) and (max-width: 899px) and (-webkit-min-device-pixel-ratio: 1) {
  .header {
    &__burger {
      display: block;
    }
    &__sideName {
      display: none;
    }
    &__wrp {
      display: block;
      width: 100%;
    }
    &__mobCity {
      display: block;
      &:first-of-type {
        margin-right: -45%;
      }
    }
    &__searchWrap {
      padding: 25px 50px 29px;
      margin-top: 0;
    }
    &__logo {
      width: 100%;
      padding: 0 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__city {
      display: none;
    }
    &__form {
      width: 90%;
    }
    &__input {
      font-size: 16px;
      height: 40px;
    }
    &__send {
      height: 40px;
    }
    &__cityName {
      font-size: 16px;
      color: #3B5171;
    }
    &__mobLink {
      font-size: 16px;
    }
    &__mob {
      display: none;
    }
  }
}

/*768*/

@media screen and (min-width: 601px) and (max-width: 798px) and (-webkit-min-device-pixel-ratio: 1) {
  .header {
    &__burger {
      display: block;
    }
    &__sideName {
      display: none;
    }
    &__wrp {
      display: block;
      width: 100%;
    }
    &__mobCity {
      display: block;
      &:first-of-type {
        margin-right: -45%;
      }
    }
    &__searchWrap {
      padding: 25px 50px 29px;
      margin-top: 0;
    }
    &__logo {
      width: 100%;
      padding: 0 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__city {
      display: none;
    }
    &__form {
      width: 90%;
    }
    &__input {
      font-size: 16px;
      height: 40px;
    }
    &__send {
      height: 40px;
    }
    &__cityName {
      font-size: 16px;
      color: #3B5171;
    }
    &__mobLink {
      font-size: 16px;
    }
    &__mob {
      display: none;
    }
  }
}

/*600*/

@media screen and (min-width: 600px) and (max-width: 699px) and (-webkit-min-device-pixel-ratio: 1) {
  .header {
    &__burger {
      display: block;
    }
    &__sideName {
      display: none;
    }
    &__wrp {
      display: block;
      width: 100%;
    }
    &__mobCity {
      display: block;
      &:first-of-type {
        margin-right: -30%;
      }
    }
    &__searchWrap {
      padding: 25px 50px 29px;
      margin-top: 0;
    }
    &__logo {
      width: 100%;
      padding: 0 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__city {
      display: none;
    }
    &__form {
      width: 90%;
    }
    &__input {
      font-size: 16px;
      height: 40px;
    }
    &__send {
      height: 40px;
    }
    &__cityName {
      font-size: 16px;
      color: #3B5171;
    }
    &__mobLink {
      font-size: 16px;
    }
    &__mob {
      display: none;
    }
  }
}

/*414*/

@media screen and (min-width: 410px) and (max-width: 598px) and (-webkit-min-device-pixel-ratio: 1) {
  .header {
    &__burger {
      display: block;
    }
    &__sideName {
      display: none;
    }
    &__wrp {
      display: block;
      width: 100%;
    }
    &__mobCity {
      display: block;
    }
    &__searchWrap {
      padding: 12px 30px 12px;
      margin-top: 0;
      display: block;
      background: transparent;
    }
    &__logo {
      width: 100%;
      padding: 0 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        max-width: 70%;
      }
    }
    &__city {
      display: none;
    }
    &__form {
      width: 90%;
    }
    &__input {
      font-size: 15px;
      height: 40px;
    }
    &__send {
      height: 40px;
      background: #fff url(../images/search2.png) no-repeat;
      background-position: center;
    }
    &__cityName {
      font-size: 15px;
      color: #3B5171;
    }
    &__mobLink {
      font-size: 15px;
    }
    &__mob {
      display: none;
    }
  }
}

/*384*/

@media screen and (min-width: 383px) and (max-width: 409px) and (-webkit-min-device-pixel-ratio: 1) {
  .header {
    &__burger {
      display: block;
    }
    &__sideName {
      display: none;
    }
    &__wrp {
      display: block;
      width: 100%;
    }
    &__mobCity {
      display: block;
    }
    &__searchWrap {
      padding: 12px 30px 12px;
      margin-top: 0;
      display: block;
      background: transparent;
    }
    &__logo {
      width: 100%;
      padding: 0 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        max-width: 70%;
      }
    }
    &__city {
      display: none;
    }
    &__form {
      width: 90%;
    }
    &__input {
      font-size: 15px;
      height: 40px;
    }
    &__send {
      height: 40px;
      background: #fff url(../images/search2.png) no-repeat;
      background-position: center;
    }
    &__cityName {
      font-size: 15px;
      color: #3B5171;
    }
    &__mobLink {
      font-size: 15px;
    }
    &__mob {
      display: none;
    }
  }
}

/*375*/

@media screen and (min-width: 370px) and (max-width: 382px) and (-webkit-min-device-pixel-ratio: 1) {
  .header {
    &__burger {
      display: block;
    }
    &__sideName {
      display: none;
    }
    &__wrp {
      display: block;
      width: 100%;
    }
    &__mobCity {
      display: block;
    }
    &__searchWrap {
      padding: 12px 30px 12px;
      margin-top: 0;
      display: block;
      background: transparent;
    }
    &__logo {
      width: 100%;
      padding: 0 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        max-width: 70%;
      }
    }
    &__city {
      display: none;
    }
    &__form {
      width: 90%;
    }
    &__input {
      font-size: 15px;
      height: 40px;
    }
    &__send {
      height: 40px;
      background: #fff url(../images/search2.png) no-repeat;
      background-position: center;
    }
    &__cityName {
      font-size: 15px;
      color: #3B5171;
    }
    &__mobLink {
      font-size: 15px;
    }
    &__mob {
      display: none;
    }
  }
}

/*360*/

@media screen and (min-width: 355px) and (max-width: 369px) and (-webkit-min-device-pixel-ratio: 1) {
  .header {
    &__burger {
      display: block;
    }
    &__sideName {
      display: none;
    }
    &__wrp {
      display: block;
      width: 100%;
    }
    &__mobCity {
      display: block;
    }
    &__searchWrap {
      padding: 12px 30px 12px;
      margin-top: 0;
      display: block;
      background: transparent;
    }
    &__logo {
      width: 100%;
      padding: 0 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        max-width: 70%;
      }
    }
    &__city {
      display: none;
    }
    &__form {
      width: 90%;
    }
    &__input {
      font-size: 15px;
      height: 40px;
    }
    &__send {
      height: 40px;
      background: #fff url(../images/search2.png) no-repeat;
      background-position: center;
    }
    &__cityName {
      font-size: 15px;
      color: #3B5171;
    }
    &__mobLink {
      font-size: 15px;
    }
    &__mob {
      display: none;
    }
  }
}

/*320*/

@media screen and (max-width: 350px) and (-webkit-min-device-pixel-ratio: 1) {
  .header {
    &__burger {
      display: block;
    }
    &__sideName {
      display: none;
    }
    &__wrp {
      display: block;
      width: 100%;
    }
    &__mobCity {
      display: block;
    }
    &__searchWrap {
      padding: 12px 30px 12px;
      margin-top: 0;
      display: block;
      background: transparent;
    }
    &__logo {
      width: 100%;
      padding: 0 7px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        max-width: 65%;
      }
    }
    &__city {
      display: none;
    }
    &__form {
      width: 90%;
    }
    &__input {
      font-size: 15px;
      height: 40px;
    }
    &__send {
      height: 40px;
      background: #fff url(../images/search2.png) no-repeat;
      background-position: center;
    }
    &__cityName {
      font-size: 15px;
      color: #3B5171;
    }
    &__mobLink {
      font-size: 15px;
    }
    &__mob {
      display: none;
    }
  }
}
