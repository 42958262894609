.list {
  &__row {
    width: 67%;
    margin: auto;
  }
  &__info {
    display: flex;
    align-items: center;
  }
  &__img {
    max-width: 220.63px;
    max-height: 220.63px;
    min-width: 220.63px;
    min-height: 220.63px;
    margin-right: 5%;
  }
  &__txt {
    font-family: $font-family-base;
    font-size: 20px;
    font-weight: 300;
    color: #3B5171;
    width: 75%;
  }
  &__span {
    font-weight: 400;
  }
  &__head {
    font-family: $font-family-base;
    font-size: 36px;
    font-weight: 300;
    color: #3B5171;
    margin: 42px 0 37px;
  }
  &__heads {
    font-family: $font-family-base;
    font-size: 18px;
    font-weight: 300;
    color: #3B5171;
    margin: 0;
  }
  &__text {
    font-family: $font-family-base;
    font-size: 18px;
    font-weight: 300;
    color: #A7A7A7;
    margin: 0;
    a {
      color: #A7A7A7;
    }
  }
  .col-sm-4 {
    width: 27%;
  }
  .col-sm-3 {
    width: 24%;
  }
  .col-sm-4,
  .col-sm-3,
  .col-sm-2,
  .col-sm-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 62px;
  }
  &__spans {
    margin-right: 3.5%;
    display: inline;
  }
  table {
    width: 100%;
    margin-bottom: 45px;
    margin-top: 40px;
  }
  th {
    margin-top: 40px;
    border: 1px solid rgba(83, 83, 83, 0.1);
    padding: 0 15px;
    &:not(:first-of-type) {
      min-width: 33.3% !important;
    }
  }
  td:not(:first-of-type) {
    min-width: 33.3% !important;
  }
  tr {
    height: 62px;
    &:not(:first-of-type) {
      border-bottom: 1px solid rgba(59, 81, 113, 0.2);
    }
  }
  .list__heads {
    display: flex;
    width: 100%;
    text-align: center;
    margin-left: 2%;
  }
  .list__text {
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-left: 15.5%;
  }
  &__td {
    border-bottom: 1px solid rgba(59, 81, 113, 0.2);
    .col-sm-4 {
      .list__text {
        display: flex;
        width: 100%;
        padding-left: 15.5%;
      }
    }
    &:last-of-type {
      margin-bottom: 45px;
    }
  }
  &__pagination {
    display: inline-block;
    margin-bottom: 32px;
    margin-left: 3%;
  }
  &__link {
    color: #3B5171;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    border-radius: 20px;
    font-family: $font-family-base;
    font-size: 18px;
    font-weight: 300;
  }
  &__active {
    background: #3B5171;
    color: #fff;
    &:hover {
      background: #3B5171;
      color: #fff;
    }
  }
}

/*1600*/

@media screen and (min-width: 1501px) and (max-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .list {
    &__txt {
      font-size: 18px;
    }
  }
}

/*1440*/

@media screen and (min-width: 1401px) and (max-width: 1500px) and (-webkit-min-device-pixel-ratio: 1) {
  .list {
    &__txt {
      font-size: 18px;
    }
    &__text {
      font-size: 16px;
    }
    &__heads {
      font-size: 16px;
    }
    &__link {
      font-size: 16px;
      padding: 8px 15px;
    }
    &__row {
      width: 75%;
    }
  }
}

/*1366*/

@media screen and (min-width: 1301px) and (max-width: 1400px) and (-webkit-min-device-pixel-ratio: 1) {
  .list {
    &__row {
      width: 75%;
    }
    &__txt {
      font-size: 18px;
    }
    &__text {
      font-size: 16px;
    }
    &__heads {
      font-size: 16px;
    }
    &__link {
      font-size: 16px;
      padding: 8px 15px;
    }
    &__head {
      font-size: 33px;
    }
    &__row {
      width: 89%;
    }
  }
}

/*1280*/

@media screen and (min-width: 1200px) and (max-width: 1300px) and (-webkit-min-device-pixel-ratio: 1) {
  .list {
    &__txt {
      font-size: 18px;
    }
    &__text {
      font-size: 16px;
    }
    &__heads {
      font-size: 16px;
    }
    &__link {
      font-size: 16px;
      padding: 8px 15px;
    }
    &__head {
      font-size: 33px;
    }
    &__row {
      width: 93%;
    }
  }
}

/*1024*/

@media screen and (min-width: 900px) and (max-width: 1199px) and (-webkit-min-device-pixel-ratio: 1) {
  .list {
    &__col {
      width: 88%;
      margin: auto;
    }
    &__row {
      width: 100%;
      margin: 0;
    }
    &__txt {
      font-size: 18px;
    }
    &__text {
      font-size: 16px;
    }
    &__heads {
      font-size: 16px;
    }
    &__link {
      font-size: 16px;
      padding: 8px 15px;
    }
    &__head {
      font-size: 33px;
    }
  }
}

/*800*/

@media screen and (min-width: 799px) and (max-width: 899px) and (-webkit-min-device-pixel-ratio: 1) {
  .list {
    &__col {
      width: 88%;
      margin: auto;
    }
    &__txt {
      font-size: 18px;
      width: 100%;
    }
    &__img {
      max-width: 100%;
      max-height: unset;
      min-width: 440px;
      min-height: unset;
      display: block;
      margin: auto;
    }
    &__text {
      font-size: 14px;
      text-align: center;
    }
    &__heads {
      font-size: 14px;
      text-align: center;
    }
    &__link {
      font-size: 16px;
      padding: 8px 15px;
    }
    &__row {
      width: 100%;
      margin: 0;
    }
    &__head {
      font-size: 33px;
    }
    &__info {
      display: block;
    }
  }
}

/*768*/

@media screen and (min-width: 601px) and (max-width: 798px) and (-webkit-min-device-pixel-ratio: 1) {
  .list {
    &__col {
      width: 88%;
      margin: auto;
    }
    &__txt {
      font-size: 18px;
      width: 100%;
    }
    &__img {
      max-width: 100%;
      max-height: unset;
      min-width: 440px;
      min-height: unset;
      display: block;
      margin: auto;
    }
    &__text {
      font-size: 14px;
      text-align: center;
    }
    &__heads {
      font-size: 14px;
      text-align: center;
    }
    &__link {
      font-size: 16px;
      padding: 8px 15px;
    }
    &__row {
      width: 100%;
      margin: 0;
    }
    &__head {
      font-size: 33px;
    }
    &__info {
      display: block;
    }
  }
}

/*600*/

@media screen and (min-width: 600px) and (max-width: 699px) and (-webkit-min-device-pixel-ratio: 1) {
  .list {
    &__col {
      width: 88%;
      margin: auto;
    }
    &__txt {
      font-size: 18px;
      width: 100%;
    }
    &__img {
      max-width: 100%;
      max-height: unset;
      min-width: 440px;
      min-height: unset;
      display: block;
      margin: auto;
    }
    &__text {
      font-size: 14px;
      text-align: center;
    }
    &__heads {
      font-size: 14px;
      text-align: left;
      width: 100%;
    }
    &__td {
      .col-sm-3,
      .col-sm-2,
      .col-sm-1 {
        display: none;
      }
      .col-sm-4 {
        width: 100%;
        .list__text {
          margin: auto;
          width: fit-content;
        }
      }
    }
    &__th {
      border: 1px solid rgba(83, 83, 83, 0.1);
      .col-sm-3,
      .col-sm-2,
      .col-sm-1,
      .col-sm-4 {
        width: 100%;
        border: none;
      }
      .col-sm-3 {
        &:last-of-type {
          border: none;
        }
      }
      .col-sm-3,
      .col-sm-2,
      .col-sm-1 {
        padding-left: 9%;
      }
      .col-sm-4 {
        border-bottom: 1px solid rgba(83, 83, 83, 0.1);
      }
    }
    &__link {
      font-size: 16px;
      padding: 8px 15px;
    }
    &__row {
      width: 100%;
      margin: 0;
    }
    &__head {
      font-size: 33px;
    }
    &__info {
      display: block;
    }
  }
}

/*414*/

@media screen and (min-width: 410px) and (max-width: 598px) and (-webkit-min-device-pixel-ratio: 1) {
  .list {
    &__col {
      width: 85%;
      margin: auto;
    }
    &__txt {
      font-size: 16px;
      width: 100%;
    }
    &__img {
      max-width: 100%;
      max-height: unset;
      min-width: 300px;
      min-height: unset;
      display: block;
      margin: auto;
    }
    &__text {
      font-size: 14px;
      text-align: center;
    }
    &__heads {
      font-size: 14px;
      text-align: left;
      width: 100%;
    }
    &__td {
      .col-sm-3,
      .col-sm-2,
      .col-sm-1 {
        display: none;
      }
      .col-sm-4 {
        width: 100%;
        .list__text {
          margin: auto;
          width: fit-content;
        }
      }
    }
    &__th {
      border: 1px solid rgba(83, 83, 83, 0.1);
      .col-sm-3,
      .col-sm-2,
      .col-sm-1,
      .col-sm-4 {
        width: 100%;
        border: none;
      }
      .col-sm-3 {
        &:last-of-type {
          border: none;
        }
      }
      .col-sm-3,
      .col-sm-2,
      .col-sm-1 {
        padding-left: 9%;
      }
      .col-sm-4 {
        border-bottom: 1px solid rgba(83, 83, 83, 0.1);
      }
    }
    &__link {
      font-size: 16px;
      padding: 8px 15px;
    }
    &__row {
      width: 100%;
      margin: 0;
    }
    &__head {
      font-size: 25px;
    }
    &__info {
      display: block;
    }
  }
}

/*384*/

@media screen and (min-width: 383px) and (max-width: 409px) and (-webkit-min-device-pixel-ratio: 1) {
  .list {
    &__col {
      width: 85%;
      margin: auto;
    }
    &__txt {
      font-size: 16px;
      width: 100%;
    }
    &__img {
      max-width: 100%;
      max-height: unset;
      min-width: 300px;
      min-height: unset;
      display: block;
      margin: auto;
    }
    &__text {
      font-size: 14px;
      text-align: center;
    }
    &__heads {
      font-size: 14px;
      text-align: left;
      width: 100%;
    }
    &__td {
      .col-sm-3,
      .col-sm-2,
      .col-sm-1 {
        display: none;
      }
      .col-sm-4 {
        width: 100%;
        .list__text {
          margin: auto;
          width: fit-content;
        }
      }
    }
    &__th {
      border: 1px solid rgba(83, 83, 83, 0.1);
      .col-sm-3,
      .col-sm-2,
      .col-sm-1,
      .col-sm-4 {
        width: 100%;
        border: none;
      }
      .col-sm-3 {
        &:last-of-type {
          border: none;
        }
      }
      .col-sm-3,
      .col-sm-2,
      .col-sm-1 {
        padding-left: 9%;
      }
      .col-sm-4 {
        border-bottom: 1px solid rgba(83, 83, 83, 0.1);
      }
    }
    &__link {
      font-size: 16px;
      padding: 8px 15px;
    }
    &__row {
      width: 100%;
      margin: 0;
    }
    &__head {
      font-size: 25px;
    }
    &__info {
      display: block;
    }
  }
}

/*375*/

@media screen and (min-width: 370px) and (max-width: 382px) and (-webkit-min-device-pixel-ratio: 1) {
  .list {
    &__col {
      width: 85%;
      margin: auto;
    }
    &__txt {
      font-size: 16px;
      width: 100%;
    }
    &__img {
      max-width: 100%;
      max-height: unset;
      min-width: 300px;
      min-height: unset;
      display: block;
      margin: auto;
    }
    &__text {
      font-size: 14px;
      text-align: center;
    }
    &__heads {
      font-size: 14px;
      text-align: left;
      width: 100%;
    }
    &__td {
      .col-sm-3,
      .col-sm-2,
      .col-sm-1 {
        display: none;
      }
      .col-sm-4 {
        width: 100%;
        .list__text {
          margin: auto;
          width: fit-content;
        }
      }
    }
    &__th {
      border: 1px solid rgba(83, 83, 83, 0.1);
      .col-sm-3,
      .col-sm-2,
      .col-sm-1,
      .col-sm-4 {
        width: 100%;
        border: none;
      }
      .col-sm-3 {
        &:last-of-type {
          border: none;
        }
      }
      .col-sm-3,
      .col-sm-2,
      .col-sm-1 {
        padding-left: 9%;
      }
      .col-sm-4 {
        border-bottom: 1px solid rgba(83, 83, 83, 0.1);
      }
    }
    &__link {
      font-size: 16px;
      padding: 8px 15px;
    }
    &__row {
      width: 100%;
      margin: 0;
    }
    &__head {
      font-size: 25px;
    }
    &__info {
      display: block;
    }
  }
}

/*360*/

@media screen and (min-width: 355px) and (max-width: 369px) and (-webkit-min-device-pixel-ratio: 1) {
  .list {
    &__col {
      width: 83%;
      margin: auto;
    }
    &__txt {
      font-size: 16px;
      width: 100%;
    }
    &__img {
      max-width: 100%;
      max-height: unset;
      min-width: 300px;
      min-height: unset;
      display: block;
      margin: auto;
    }
    &__text {
      font-size: 14px;
      text-align: center;
    }
    &__heads {
      font-size: 14px;
      text-align: left;
      width: 100%;
    }
    &__td {
      .col-sm-3,
      .col-sm-2,
      .col-sm-1 {
        display: none;
      }
      .col-sm-4 {
        width: 100%;
        .list__text {
          margin: auto;
          width: fit-content;
        }
      }
    }
    &__th {
      border: 1px solid rgba(83, 83, 83, 0.1);
      .col-sm-3,
      .col-sm-2,
      .col-sm-1,
      .col-sm-4 {
        width: 100%;
        border: none;
      }
      .col-sm-3 {
        &:last-of-type {
          border: none;
        }
      }
      .col-sm-3,
      .col-sm-2,
      .col-sm-1 {
        padding-left: 9%;
      }
      .col-sm-4 {
        border-bottom: 1px solid rgba(83, 83, 83, 0.1);
      }
    }
    &__link {
      font-size: 16px;
      padding: 8px 15px;
    }
    &__row {
      width: 100%;
      margin: 0;
    }
    &__head {
      font-size: 25px;
    }
    &__info {
      display: block;
    }
  }
}

/*320*/

@media screen and (max-width: 350px) and (-webkit-min-device-pixel-ratio: 1) {
  .list {
    &__col {
      width: 88%;
      margin: auto;
    }
    &__txt {
      font-size: 16px;
      width: 100%;
    }
    &__img {
      max-width: 100%;
      max-height: unset;
      min-width: 300px;
      min-height: unset;
      display: block;
      margin: auto;
    }
    &__text {
      font-size: 14px;
      text-align: center;
    }
    &__heads {
      font-size: 14px;
      text-align: left;
      width: 100%;
    }
    &__td {
      .col-sm-3,
      .col-sm-2,
      .col-sm-1 {
        display: none;
      }
      .col-sm-4 {
        width: 100%;
        .list__text {
          margin: auto;
          width: fit-content;
        }
      }
    }
    &__th {
      border: 1px solid rgba(83, 83, 83, 0.1);
      .col-sm-3,
      .col-sm-2,
      .col-sm-1,
      .col-sm-4 {
        width: 100%;
        border: none;
      }
      .col-sm-3 {
        &:last-of-type {
          border: none;
        }
      }
      .col-sm-3,
      .col-sm-2,
      .col-sm-1 {
        padding-left: 9%;
      }
      .col-sm-4 {
        border-bottom: 1px solid rgba(83, 83, 83, 0.1);
      }
    }
    &__link {
      font-size: 16px;
      padding: 8px 15px;
    }
    &__row {
      width: 100%;
      margin: 0;
    }
    &__head {
      font-size: 22px;
    }
    &__info {
      display: block;
    }
  }
}
