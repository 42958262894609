.content {
  &__row {
    width: 80%;
    margin: auto;
  }
  &__silderWrap {
    width: 100%;
    padding: 60px 0;
    overflow: hidden;
    .slick-dots {
      width: auto;
      transform: rotate(90deg) translate(0, -50%);
      bottom: unset;
      top: 50%;
      right: 0;
      li {
        button {
          border: 1px solid #fff;
          border-radius: 30px;
          width: 8.61px;
          height: 8.61px;
          &::before {
            display: none;
          }
        }
      }
      .slick-active {
        button {
          background: #fff;
          width: 12.35px;
          height: 12.35px;
        }
      }
    }
    .slick-dotted.slick-slider {
      margin: 0;
    }
    .slick-initialized .slick-slide {
      position: relative;
    }
  }
  &__words {
    position: absolute;
    bottom: 5%;
    left: 2%;
    h1 {
      color: #fff;
      font-size: 60px;
      font-family: $font-family-base;
      font-weight: 600;
    }
    p {
      color: #fff;
      font-size: 20px;
      font-family: $font-family-base;
      font-weight: 300;
    }
  }
}

/*1600*/

@media screen and (min-width: 1501px) and (max-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .content {
    &__words {
      left: 5%;
      h1 {
        font-size: 55px;
      }
      p {
        width: 100%;
        font-size: 18px;
      }
    }
    .slick-slide {
      img {
        min-width: 1500px;
      }
    }
  }
}

/*1440*/

@media screen and (min-width: 1401px) and (max-width: 1500px) and (-webkit-min-device-pixel-ratio: 1) {
  .content {
    &__words {
      left: 5%;
      h1 {
        font-size: 55px;
      }
      p {
        width: 100%;
        font-size: 16px;
      }
    }
    .slick-slide {
      img {
        min-width: 1500px;
      }
    }
  }
}

/*1366*/

@media screen and (min-width: 1301px) and (max-width: 1400px) and (-webkit-min-device-pixel-ratio: 1) {
  .content {
    &__words {
      left: 5%;
      h1 {
        font-size: 50px;
      }
      p {
        width: 100%;
        font-size: 16px;
      }
    }
    .slick-slide {
      img {
        min-width: 1500px;
      }
    }
  }
}

/*1280*/

@media screen and (min-width: 1200px) and (max-width: 1300px) and (-webkit-min-device-pixel-ratio: 1) {
  .content {
    &__words {
      left: 5%;
      h1 {
        font-size: 50px;
      }
      p {
        width: 100%;
        font-size: 16px;
      }
    }
    &__row {
      width: 92%;
    }
    .slick-slide {
      img {
        min-width: 1500px;
      }
    }
  }
}

/*1024*/

@media screen and (min-width: 900px) and (max-width: 1199px) and (-webkit-min-device-pixel-ratio: 1) {
  .content {
    height: 640px;
    &__silderWrap {
      width: 100%;
      padding: 0;
      height: 100%;
      .slick-dots {
        transform: unset;
        top: auto;
        bottom: 40px;
        left: 0;
        right: 0;
        margin: auto;
      }
      .slick-slider,
      .slick-list,
      .slick-track,
      .slick-slide {
        height: 100%;
      }
      .slick-slide img {
        height: 100%;
        max-width: 200%;
      }
    }
    &__words {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      h1 {
        font-size: 50px;
      }
      p {
        width: 100%;
        font-size: 16px;
      }
    }
    &__row {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }
}

/*800*/

@media screen and (min-width: 799px) and (max-width: 899px) and (-webkit-min-device-pixel-ratio: 1) {
  .content {
    height: 640px;
    &__silderWrap {
      width: 100%;
      padding: 0;
      height: 100%;
      .slick-dots {
        transform: unset;
        top: auto;
        bottom: 40px;
        left: 0;
        right: 0;
        margin: auto;
      }
      .slick-slider,
      .slick-list,
      .slick-track,
      .slick-slide {
        height: 100%;
      }
      .slick-slide img {
        height: 100%;
        max-width: 200%;
      }
    }
    &__words {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      h1 {
        font-size: 50px;
      }
      p {
        width: 100%;
        font-size: 16px;
      }
    }
    &__row {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }
}

/*768*/

@media screen and (min-width: 601px) and (max-width: 798px) and (-webkit-min-device-pixel-ratio: 1) {
  .content {
    height: 640px;
    &__silderWrap {
      width: 100%;
      padding: 0;
      height: 100%;
      .slick-dots {
        transform: unset;
        top: auto;
        bottom: 40px;
        left: 0;
        right: 0;
        margin: auto;
      }
      .slick-slider,
      .slick-list,
      .slick-track,
      .slick-slide {
        height: 100%;
      }
      .slick-slide img {
        height: 100%;
        max-width: 200%;
      }
    }
    &__words {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      h1 {
        font-size: 50px;
      }
      p {
        width: 100%;
        font-size: 16px;
      }
    }
    &__row {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }
}

/*600*/

@media screen and (min-width: 600px) and (max-width: 699px) and (-webkit-min-device-pixel-ratio: 1) {
  .content {
    height: 460px;
    &__silderWrap {
      width: 100%;
      padding: 0;
      height: 100%;
      .slick-dots {
        transform: unset;
        top: auto;
        bottom: 40px;
        left: 0;
        right: 0;
        margin: auto;
      }
      .slick-slider,
      .slick-list,
      .slick-track,
      .slick-slide {
        height: 100%;
      }
      .slick-slide img {
        height: 100%;
        max-width: 200%;
      }
    }
    &__words {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      h1 {
        font-size: 50px;
      }
      p {
        width: 100%;
        font-size: 16px;
      }
    }
    &__row {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }
}

/*414*/

@media screen and (min-width: 410px) and (max-width: 598px) and (-webkit-min-device-pixel-ratio: 1) {
  .content {
    height: 450px;
    &__silderWrap {
      width: 100%;
      padding: 0;
      height: 100%;
      .slick-dots {
        transform: unset;
        top: auto;
        bottom: 40px;
        left: 0;
        right: 0;
        margin: auto;
      }
      .slick-slider,
      .slick-list,
      .slick-track,
      .slick-slide {
        height: 100%;
      }
      .slick-slide img {
        height: 100%;
        max-width: 200%;
      }
    }
    &__words {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 80%;
      h1 {
        font-size: 50px;
      }
      p {
        width: 100%;
        font-size: 16px;
      }
    }
    &__row {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }
}

/*384*/

@media screen and (min-width: 383px) and (max-width: 409px) and (-webkit-min-device-pixel-ratio: 1) {
  .content {
    height: 450px;
    &__silderWrap {
      width: 100%;
      padding: 0;
      height: 100%;
      .slick-dots {
        transform: unset;
        top: auto;
        bottom: 40px;
        left: 0;
        right: 0;
        margin: auto;
      }
      .slick-slider,
      .slick-list,
      .slick-track,
      .slick-slide {
        height: 100%;
      }
      .slick-slide img {
        height: 100%;
        max-width: 200%;
      }
    }
    &__words {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 80%;
      h1 {
        font-size: 50px;
      }
      p {
        width: 100%;
        font-size: 16px;
      }
    }
    &__row {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }
}

/*375*/

@media screen and (min-width: 370px) and (max-width: 382px) and (-webkit-min-device-pixel-ratio: 1) {
  .content {
    height: 400px;
    &__silderWrap {
      width: 100%;
      padding: 0;
      height: 100%;
      .slick-dots {
        transform: unset;
        top: auto;
        bottom: 40px;
        left: 0;
        right: 0;
        margin: auto;
      }
      .slick-slider,
      .slick-list,
      .slick-track,
      .slick-slide {
        height: 100%;
      }
      .slick-slide img {
        height: 100%;
        max-width: 200%;
      }
    }
    &__words {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 80%;
      h1 {
        font-size: 35px;
      }
      p {
        width: 100%;
        font-size: 14px;
      }
    }
    &__row {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }
}

/*360*/

@media screen and (min-width: 355px) and (max-width: 369px) and (-webkit-min-device-pixel-ratio: 1) {
  .content {
    height: 400px;
    &__silderWrap {
      width: 100%;
      padding: 0;
      height: 100%;
      .slick-dots {
        transform: unset;
        top: auto;
        bottom: 40px;
        left: 0;
        right: 0;
        margin: auto;
      }
      .slick-slider,
      .slick-list,
      .slick-track,
      .slick-slide {
        height: 100%;
      }
      .slick-slide img {
        height: 100%;
        max-width: 200%;
      }
    }
    &__words {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 80%;
      h1 {
        font-size: 35px;
      }
      p {
        width: 100%;
        font-size: 14px;
      }
    }
    &__row {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }
}

/*320*/

@media screen and (max-width: 350px) and (-webkit-min-device-pixel-ratio: 1) {
  .content {
    height: 400px;
    &__silderWrap {
      width: 100%;
      padding: 0;
      height: 100%;
      .slick-dots {
        transform: unset;
        top: auto;
        bottom: 40px;
        left: 0;
        right: 0;
        margin: auto;
      }
      .slick-slider,
      .slick-list,
      .slick-track,
      .slick-slide {
        height: 100%;
      }
      .slick-slide img {
        height: 100%;
        max-width: 200%;
      }
    }
    &__words {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 80%;
      h1 {
        font-size: 35px;
      }
      p {
        width: 100%;
        font-size: 14px;
      }
    }
    &__row {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }
}
