.description {
  &__row {
    display: flex;
    width: 67%;
    margin: auto;
  }

  &__col {
    width: 100%;
  }

  &__slider {
    // width: 40%;
    // background: black;
  }

  &__imgNav {
    max-width: 121px;
    max-height: 121px;
    min-width: 121px;
    min-height: 121px;
  }

  &__imgFor {
    max-width: 460px;
    max-height: 250px;
    min-width: 460px;
    min-height: 250px;
  }

  .slick-prev {
    transform: rotate(180deg);

    &:before {
      content: url(../images/arrow5.png);
    }
  }

  .slick-next {

    // transform: rotate(180deg);
    &:before {
      content: url(../images/arrow5.png);
    }
  }

  .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__head {
    font-family: $font-family-base;
    font-size: 36px;
    font-weight: 300;
    color: #3B5171;
    margin: 43px 0 135px;
  }

  &__desc {
    font-family: $font-family-base;
    font-size: 20px;
    font-weight: 400;
    color: #3B5171;
    margin: 87px 0 20px;
  }

  &__heads {
    font-family: $font-family-base;
    font-size: 20px;
    font-weight: 400;
    color: #3B5171;
    margin: 0 0 45px;

    &:not(:first-of-type) {
      margin-top: 40px;
    }
  }

  &__descInfo {
    font-family: $font-family-base;
    font-size: 20px;
    font-weight: 300;
    color: #3B5171;
    margin: 0 0 55px;
    width: 100%;
  }

  &__txt {
    font-family: $font-family-base;
    font-size: 20px;
    font-weight: 300;
    color: #3B5171;
    margin: 0;
  }

  &__characters {
    margin-left: 8%;

    .col-sm-8,
    .col-sm-4 {
      padding: 0;
    }
  }

  &__btn {
    width: 177px;
    height: 52px;
    background: #3B5171;
    font-family: $font-family-base;
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    margin-top: 45px;
  }

  /* The Modal (background) */
  .modal {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    padding-top: 100px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);

    /* Black w/ opacity */
    &__num {
      position: relative;

      label {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translate(0, -50%);
        color: #A7A7A7;
        font-size: 18px;
      }

      input {
        border-bottom: 1px solid rgba(59, 81, 113, 0.2);
        width: 100%;
        color: #A7A7A7;
        font-size: 18px;
        padding: 0 40px 0 20px;
        font-weight: 300;
        font-family: $font-family-base;
      }
    }

    img {
      max-width: 300px;
    }
  }

  .col-sm-6 {
    &--img {
      height: 330px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  /* Modal Content */
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 53%;
    position: relative;
  }

  .modal-label {
    color: #3B5171;
    font-size: 20px;
    font-weight: 300;
    font-family: $font-family-base;
    margin: 0;
  }

  .modal-con {
    padding: 0 17%;

    .col-sm-4 {
      height: 40px;
      display: flex;
      align-items: center;
    }
  }

  .modal-btns {
    padding: 52px 0 47px;
    display: flex;
    justify-content: center;
  }

  .order {
    width: 177px;
    height: 52px;
    background: #FFC400;
    color: #fff;
    font-size: 18px;
    font-weight: 300;
  }

  .cancel {
    width: 106px;
    height: 52px;
    background: #3B5171;
    color: #fff;
    font-size: 18px;
    font-weight: 300;
  }

  .modal-input {
    border-bottom: 1px solid rgba(59, 81, 113, 0.2);
    width: 100%;
    color: #A7A7A7;
    font-size: 18px;
    padding: 0 40px 0 20px;
    font-weight: 300;
    font-family: $font-family-base;
    height: 40px;
  }

  .modal-heads {
    color: #3B5171;
    font-size: 20px;
    font-weight: 300;
    font-family: $font-family-base;
    margin: 27px 0 6px;

    &--num {
      margin: 0 0 6px;
    }
  }

  .row {
    &--num {
      margin: 27px 0 35px;

      .col-sm-6,
      .col-sm-4 {
        padding: 0;
      }
    }

    &--desc {
      width: 80%;
      margin: auto;
    }
  }

  .modal-txt {
    color: #A7A7A7;
    font-size: 18px;
    font-weight: 300;
    font-family: $font-family-base;
    margin: 0;
  }

  .modal-line {
    width: 90%;
    margin: 0 auto 40px;
  }

  .modal-text {
    color: #3B5171;
    font-size: 20px;
    font-weight: 300;
    font-family: $font-family-base;
    margin: 20px 0;
    text-align: center;
    width: 100%;
  }

  .modal-order {
    color: #3B5171;
    font-size: 22px;
    font-weight: 400;
    font-family: $font-family-base;
    margin: 0;
    text-align: center;
    padding: 20px 0;
    box-shadow: 0 3px 13px 0px rgba(0, 0, 0, 0.16);
  }

  /* The Close Button */
  .close {
    color: #707070;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    top: 10px;
    right: 20px;
    opacity: 0.6;
  }

  .close:hover,
  .close:focus {
    opacity: 1;
    text-decoration: none;
    cursor: pointer;
  }
}

/*1600*/
@media screen and (min-width: 1501px) and (max-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .description {
    &__col {
      margin-left: 2.7%;
    }

    &__slider {
      padding: 0;
    }

    &__head {
      font-size: 32px;
    }

    &__descInfo {
      font-size: 18px;
    }

    &__txt {
      font-size: 18px;
    }

    &__btn {
      font-size: 16px;
    }
  }
}

/*1440*/
@media screen and (min-width: 1401px) and (max-width: 1500px) and (-webkit-min-device-pixel-ratio: 1) {
  .description {
    &__col {
      margin-left: 3.3%;
    }

    &__slider {
      padding: 0;
    }

    &__descInfo {
      font-size: 16px;
    }

    &__head {
      font-size: 32px;
    }

    &__txt {
      font-size: 16px;
    }

    &__btn {
      font-size: 16px;
    }

    .modal-content {
      width: 75%;
    }
  }
}

/*1366*/
@media screen and (min-width: 1301px) and (max-width: 1400px) and (-webkit-min-device-pixel-ratio: 1) {
  .description {
    &__col {
      margin-left: 3.3%;
    }

    &__slider {
      padding: 0;
    }

    &__descInfo {
      font-size: 16px;
    }

    &__head {
      font-size: 32px;
    }

    &__txt {
      font-size: 16px;
    }

    &__btn {
      font-size: 16px;
    }

    .modal-content {
      width: 80%;
    }

    .modal-heads {
      font-size: 18px;
      margin: 22px 0 6px;

      &--num {
        margin: 0 0 6px;
      }
    }

    .modal-label {
      font-size: 18px;
    }

    .modal-txt {
      font-size: 16px;
    }

    .modal-line {
      margin: 0 auto 20px;
    }

    .modal {
      padding-top: 30px;
    }
  }
}

/*1280*/
@media screen and (min-width: 1200px) and (max-width: 1300px) and (-webkit-min-device-pixel-ratio: 1) {
  .description {
    &__col {
      margin-left: 3.3%;
    }

    &__slider {
      padding: 0;
    }

    &__descInfo {
      font-size: 16px;
    }

    &__head {
      font-size: 32px;
    }

    &__col {
      width: 72.7%;
    }

    &__txt {
      font-size: 16px;
    }

    &__btn {
      font-size: 16px;
    }

    &__imgFor {
      max-width: 100%;
    }

    .modal-content {
      width: 80%;
    }

    .modal-heads {
      font-size: 18px;
      margin: 22px 0 6px;

      &--num {
        margin: 0 0 6px;
      }
    }

    .modal-label {
      font-size: 18px;
    }

    .modal-txt {
      font-size: 16px;
    }

    .modal-line {
      margin: 0 auto 20px;
    }

    .modal {
      padding-top: 30px;
    }
  }
}

/*1024*/
@media screen and (min-width: 900px) and (max-width: 1199px) and (-webkit-min-device-pixel-ratio: 1) {
  .description {
    &__col {
      margin-left: 0;
      width: 100%;
    }

    &__slider {
      padding: 0;
    }

    &__descInfo {
      font-size: 16px;
    }

    &__head {
      font-size: 32px;
    }

    &__txt {
      font-size: 16px;
    }

    &__btn {
      font-size: 16px;
    }

    &__row {
      width: 80%;
      margin: auto;
    }

    &__imgFor {
      max-width: 100%;
      min-width: 100%;
    }

    &__characters {
      margin-left: 8%;

      .col-sm-6 {
        width: 66%;
      }
    }

    .modal-content {
      width: 80%;
    }

    .modal-heads {
      font-size: 18px;
      margin: 22px 0 6px;

      &--num {
        margin: 0 0 6px;
      }
    }

    .modal-label {
      font-size: 18px;
    }

    .modal-txt {
      font-size: 16px;
    }

    .modal-line {
      margin: 0 auto 20px;
    }

    .modal {
      padding-top: 30px;
    }
  }
}

/*800*/
@media screen and (min-width: 799px) and (max-width: 899px) and (-webkit-min-device-pixel-ratio: 1) {
  .description {
    &__col {
      margin-left: 0;
      width: 100%;
    }

    &__slider {
      padding: 0;
    }

    &__descInfo {
      font-size: 16px;
    }

    &__head {
      font-size: 32px;
    }

    &__txt {
      font-size: 16px;
    }

    &__btn {
      font-size: 16px;
    }

    &__row {
      width: 88%;
      margin: auto;
    }

    .slick-slide {
      img {
        max-width: 100%;
        min-width: 100%;
      }
    }

    &__imgFor {
      max-width: 100%;
      min-width: 100%;
    }

    &__characters {
      margin-left: 8%;
      padding-right: 0;

      .col-sm-6 {
        width: 66%;
      }
    }

    .modal-content {
      width: 96%;
    }

    .modal-heads {
      font-size: 18px;
      margin: 22px 0 6px;

      &--num {
        margin: 0 0 6px;
      }
    }

    .modal-label {
      font-size: 18px;
    }

    .modal-txt {
      font-size: 16px;
    }

    .modal-line {
      margin: 0 auto 20px;
    }

    .modal {
      padding-top: 30px;
    }
  }
}

/*768*/
@media screen and (min-width: 601px) and (max-width: 798px) and (-webkit-min-device-pixel-ratio: 1) {
  .description {
    &__col {
      margin-left: 0;
      width: 100%;
    }

    &__slider {
      padding: 0;
    }

    &__descInfo {
      font-size: 16px;
    }

    &__head {
      font-size: 32px;
    }

    &__txt {
      font-size: 16px;
    }

    &__btn {
      font-size: 16px;
    }

    &__row {
      width: 88%;
      margin: auto;
    }

    .slick-slide {
      img {
        max-width: 100%;
        min-width: 100%;
      }
    }

    &__imgFor {
      max-width: 100%;
      min-width: 100%;
    }

    &__characters {
      margin-left: 6%;
      padding-right: 0;
      width: 43.66667%;

      .col-sm-6 {
        width: 66%;
      }
    }

    .modal-content {
      width: 96%;
    }

    .modal-heads {
      font-size: 18px;
      margin: 22px 0 6px;

      &--num {
        margin: 0 0 6px;
      }
    }

    .modal-label {
      font-size: 18px;
    }

    .modal-txt {
      font-size: 16px;
    }

    .modal-line {
      margin: 0 auto 20px;
    }

    .modal {
      padding-top: 30px;
    }
  }
}

/*600*/
@media screen and (min-width: 600px) and (max-width: 699px) and (-webkit-min-device-pixel-ratio: 1) {
  .description {
    &__col {
      margin-left: 0;
      width: 100%;
    }

    &__slider {
      padding: 0;
    }

    &__descInfo {
      font-size: 16px;
    }

    &__head {
      font-size: 32px;
    }

    &__txt {
      font-size: 16px;
    }

    &__btn {
      font-size: 16px;
    }

    &__row {
      width: 84%;
      margin: auto;
    }

    .slick-slide {
      img {
        max-width: 100%;
        min-width: 100%;
      }
    }

    &__imgFor {
      max-width: 100%;
      min-width: 100%;
    }

    &__characters {
      margin-left: 0;
      padding: 0;

      .col-sm-6 {
        width: 66%;
        float: left;
      }
    }

    &__btn {
      margin-bottom: 50px;
    }

    .modal-content {
      width: 96%;
    }

    .modal-heads {
      font-size: 18px;
      margin: 22px 0 6px;

      &--num {
        margin: 0 0 6px;
      }
    }

    .modal-label {
      font-size: 18px;
    }

    .modal-txt {
      font-size: 16px;
    }

    .modal-line {
      margin: 0 auto 20px;
    }

    .modal {
      padding-top: 30px;
    }

    .modal-con {
      padding: 0 13% 0 10.1%;
    }

    .modal-btns {
      padding: 52px 10% 47px 12.7%;
    }

    .row {
      &--desc {
        display: block;
      }
    }
  }
}

/*414*/
@media screen and (min-width: 410px) and (max-width: 598px) and (-webkit-min-device-pixel-ratio: 1) {
  .description {
    &__col {
      margin-left: 0;
      width: 100%;
    }

    &__slider {
      padding: 0;
    }

    &__descInfo {
      font-size: 16px;
    }

    &__head {
      font-size: 23px;
      margin: 43px 0 50px;
    }

    &__desc {
      margin: 55px 0 20px;
    }

    &__txt {
      font-size: 16px;
    }

    &__btn {
      font-size: 16px;
    }

    &__row {
      width: 84%;
      margin: auto;
    }

    .slick-slide {
      img {
        max-width: 100%;
        min-width: 100%;
        max-height: auto;
        min-height: auto;
      }
    }

    &__imgFor {
      max-width: 100%;
      min-width: 100%;
      max-height: auto;
      min-height: auto;
    }

    &__characters {
      margin-left: 0;
      padding: 0;

      .col-sm-6 {
        width: 88%;
        float: left;
      }
    }

    &__btn {
      margin-bottom: 50px;
    }

    .modal-content {
      width: 96%;
    }

    .modal-heads {
      font-size: 18px;
      margin: 22px 0 6px;

      &--num {
        margin: 0 0 6px;
      }
    }

    .modal-label {
      font-size: 18px;
    }

    .modal-txt {
      font-size: 16px;
    }

    .modal-line {
      margin: 0 auto 20px;
    }

    .modal {
      padding-top: 30px;
    }

    .modal-con {
      padding: 0 5% 0 5.1%;
    }

    .modal-btns {
      padding: 52px 10% 47px 8.7%;
    }

    .row {
      &--desc {
        display: block;
        width: 90%;
      }

      &--num {
        .col-sm-8 {
          padding: 0;
        }
      }
    }
  }
}

/*384*/
@media screen and (min-width: 383px) and (max-width: 409px) and (-webkit-min-device-pixel-ratio: 1) {
  .description {
    &__col {
      margin-left: 0;
      width: 100%;
    }

    &__slider {
      padding: 0;
    }

    &__descInfo {
      font-size: 16px;
    }

    &__head {
      font-size: 23px;
      margin: 43px 0 50px;
    }

    &__desc {
      margin: 55px 0 20px;
    }

    &__txt {
      font-size: 16px;
    }

    &__btn {
      font-size: 16px;
    }

    &__row {
      width: 84%;
      margin: auto;
    }

    .slick-slide {
      img {
        max-width: 100%;
        min-width: 100%;
        max-height: auto;
        min-height: auto;
      }
    }

    &__imgFor {
      max-width: 100%;
      min-width: 100%;
      max-height: auto;
      min-height: auto;
    }

    &__characters {
      margin-left: 0;
      padding: 0;

      .col-sm-6 {
        width: 88%;
        float: left;
      }
    }

    &__btn {
      margin-bottom: 50px;
    }

    .modal-content {
      width: 96%;
    }

    .modal-heads {
      font-size: 18px;
      margin: 22px 0 6px;

      &--num {
        margin: 0 0 6px;
      }
    }

    .modal-label {
      font-size: 18px;
    }

    .modal-txt {
      font-size: 16px;
    }

    .modal-line {
      margin: 0 auto 20px;
    }

    .modal {
      padding-top: 30px;
    }

    .modal-con {
      padding: 0 5% 0 5.1%;
    }

    .modal-btns {
      padding: 52px 10% 47px 8.7%;
    }

    .row {
      &--desc {
        display: block;
        width: 90%;
      }

      &--num {
        .col-sm-8 {
          padding: 0;
        }
      }
    }
  }
}

/*375*/
@media screen and (min-width: 370px) and (max-width: 382px) and (-webkit-min-device-pixel-ratio: 1) {
  .description {
    &__col {
      margin-left: 0;
      width: 100%;
    }

    &__slider {
      padding: 0;
    }

    &__descInfo {
      font-size: 16px;
    }

    &__head {
      font-size: 23px;
      margin: 43px 0 50px;
    }

    &__desc {
      margin: 55px 0 20px;
    }

    &__txt {
      font-size: 16px;
    }

    &__btn {
      font-size: 16px;
    }

    &__row {
      width: 84%;
      margin: auto;
    }

    .slick-slide {
      img {
        max-width: 100%;
        min-width: 100%;
        max-height: auto;
        min-height: auto;
      }
    }

    &__imgFor {
      max-width: 100%;
      min-width: 100%;
      max-height: auto;
      min-height: auto;
    }

    &__characters {
      margin-left: 0;
      padding: 0;

      .col-sm-6 {
        width: 88%;
        float: left;
      }
    }

    &__btn {
      margin-bottom: 50px;
    }

    .modal-content {
      width: 96%;
    }

    .modal-heads {
      font-size: 18px;
      margin: 22px 0 6px;

      &--num {
        margin: 0 0 6px;
      }
    }

    .modal-label {
      font-size: 18px;
    }

    .modal-txt {
      font-size: 16px;
    }

    .modal-line {
      margin: 0 auto 20px;
    }

    .modal {
      padding-top: 30px;
    }

    .modal-con {
      padding: 0 5% 0 5.1%;
    }

    .modal-btns {
      padding: 52px 10% 47px 8.7%;
    }

    .row {
      &--desc {
        display: block;
        width: 90%;
      }

      &--num {
        .col-sm-8 {
          padding: 0;
        }
      }
    }

    .order {
      width: 165px;
    }

    .cancel {
      width: 95px;
    }
  }
}

/*360*/
@media screen and (min-width: 355px) and (max-width: 369px) and (-webkit-min-device-pixel-ratio: 1) {
  .description {
    &__col {
      margin-left: 0;
      width: 100%;
    }

    &__slider {
      padding: 0;
      width: 90%;
      margin: auto;
    }

    &__descInfo {
      font-size: 16px;
      margin: 0 0 55px -6%;
    }

    &__head {
      font-size: 23px;
      margin: 43px 0 50px;
    }

    &__desc {
      margin: 55px 0 20px -6%;
    }

    &__txt {
      font-size: 16px;
    }

    &__btn {
      font-size: 16px;
    }

    &__row {
      width: 85%;
      margin: auto;
    }

    .slick-slide {
      img {
        max-width: 100%;
        min-width: 100%;
        max-height: auto;
        min-height: auto;
      }
    }

    &__imgFor {
      max-width: 100%;
      min-width: 100%;
      max-height: auto;
      min-height: auto;
    }

    &__characters {
      margin-left: 0;
      padding: 0;

      .col-sm-6 {
        width: 88%;
        float: left;
      }
    }

    &__btn {
      margin-bottom: 50px;
    }

    .modal-content {
      width: 96%;
    }

    .modal-heads {
      font-size: 18px;
      margin: 22px 0 6px;

      &--num {
        margin: 0 0 6px;
      }
    }

    .modal-label {
      font-size: 18px;
    }

    .modal-txt {
      font-size: 16px;
    }

    .modal-line {
      margin: 0 auto 20px;
    }

    .modal {
      padding-top: 30px;
    }

    .modal-con {
      padding: 0 5% 0 5.1%;
    }

    .modal-btns {
      padding: 52px 10% 47px 8.7%;
    }

    .row {
      &--desc {
        display: block;
        width: 90%;
      }

      &--num {
        .col-sm-8 {
          padding: 0;
        }
      }
    }

    .order {
      width: 165px;
    }

    .cancel {
      width: 95px;
    }
  }
}

/*320*/
@media screen and (max-width: 350px) and (-webkit-min-device-pixel-ratio: 1) {
  .description {
    &__col {
      margin-left: 0;
      width: 100%;
    }

    &__slider {
      padding: 0;
      width: 90%;
      margin: auto;
    }

    &__descInfo {
      font-size: 16px;
      margin: 0 0 55px -6%;
    }

    &__head {
      font-size: 20px;
      margin: 43px 0 50px;
    }

    &__desc {
      margin: 55px 0 20px -6%;
    }

    &__txt {
      font-size: 16px;
    }

    &__btn {
      font-size: 16px;
    }

    &__row {
      width: 88%;
      margin: auto;
    }

    .slick-slide {
      img {
        max-width: 100%;
        min-width: 100%;
        max-height: auto;
        min-height: auto;
      }
    }

    &__imgFor {
      max-width: 100%;
      min-width: 100%;
      max-height: auto;
      min-height: auto;
    }

    &__characters {
      margin-left: 0;
      padding: 0;

      .col-sm-6 {
        width: 85%;
        float: left;
      }
    }

    &__btn {
      margin-bottom: 50px;
    }

    .modal-content {
      width: 96%;
    }

    .modal-heads {
      font-size: 18px;
      margin: 22px 0 6px;

      &--num {
        margin: 0 0 6px;
      }
    }

    .modal-label {
      font-size: 18px;
    }

    .modal-txt {
      font-size: 16px;
    }

    .modal-line {
      margin: 0 auto 20px;
    }

    .modal {
      padding-top: 30px;
    }

    .modal-con {
      padding: 0 5% 0 5.1%;
    }

    .modal-btns {
      padding: 52px 10% 47px 8.7%;
    }

    .row {
      &--desc {
        display: block;
        width: 90%;
      }

      &--num {
        .col-sm-8 {
          padding: 0;
        }
      }
    }

    .order {
      width: 125px;
    }

    .cancel {
      width: 95px;
    }
  }
}
