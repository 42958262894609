.contacts {
  width: 63%;
  margin: 55px auto 0;
  &__head {
    font-family: $font-family-base;
    font-size: 36px;
    font-weight: 300;
    color: #3B5171;
    margin: 0 0 73px;
    position: relative;
    &::after {
      content: "";
      width: 52.4px;
      height: 2.08px;
      background: #FFC400;
      position: absolute;
      left: 0;
      bottom: -34px;
    }
  }
  &__txt {
    font-family: $font-family-base;
    font-size: 20px;
    font-weight: 300;
    color: #3B5171;
    margin: 0 0 46px;
    width: 75%;
  }
  &__row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
    .contacts__col:last-of-type {
      width: 23%;
    }
  }
  #karta,
  #map,
  #map2 {
    height: 325px;
    width: 855px;
  }
  &__link {
    font-family: $font-family-base;
    font-size: 18px;
    font-weight: 300;
    color: #3B5171;
    display: block;
    &:hover,
    &:focus {
      color: #3B5171;
      outline: none;
      text-decoration: none;
    }
    span {
      font-weight: 600;
    }
  }
  &__text {
    font-family: $font-family-base;
    font-size: 18px;
    font-weight: 300;
    color: #3B5171;
    margin: 0;
    &--city {
      font-weight: 600;
      position: relative;
      margin-bottom: 43px;
      &::after {
        content: "";
        width: 52.4px;
        height: 2.08px;
        background: #3B5171;
        position: absolute;
        left: 0;
        bottom: -21px;
      }
    }
    &--b {
      padding-top: 20px;
    }
    &--mood {
      margin: 31px 0 16px;
    }
  }
}

/*1600*/

@media screen and (min-width: 1501px) and (max-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .contacts {
    width: 65%;
    #karta,
    #map,
    #map2 {
      width: 690px;
    }
    &__txt {
      font-size: 18px;
      width: 70%;
    }
    &__text,
    &__link {
      font-size: 16px;
    }
  }
}

/*1440*/

@media screen and (min-width: 1401px) and (max-width: 1500px) and (-webkit-min-device-pixel-ratio: 1) {
  .contacts {
    width: 70%;
    #karta,
    #map,
    #map2 {
      width: 615px;
    }
    &__txt {
      font-size: 18px;
      width: 70%;
    }
    &__text,
    &__link {
      font-size: 16px;
    }
    &__head {
      font-size: 30px;
    }
    &__row .contacts__col:last-of-type {
      width: 30%;
    }
  }
}

/*1366*/

@media screen and (min-width: 1301px) and (max-width: 1400px) and (-webkit-min-device-pixel-ratio: 1) {
  .contacts {
    width: 78%;
    #karta,
    #map,
    #map2 {
      width: 660px;
    }
    &__txt {
      font-size: 18px;
      width: 70%;
    }
    &__text,
    &__link {
      font-size: 16px;
    }
    &__head {
      font-size: 30px;
    }
    &__row .contacts__col:last-of-type {
      width: 28%;
    }
  }
}

/*1280*/

@media screen and (min-width: 1200px) and (max-width: 1300px) and (-webkit-min-device-pixel-ratio: 1) {
  .contacts {
    width: 92%;
    #karta,
    #map,
    #map2 {
      width: 850px;
    }
    &__txt {
      font-size: 18px;
      width: 70%;
    }
    &__text,
    &__link {
      font-size: 16px;
    }
    &__head {
      font-size: 30px;
    }
    .contacts__col:last-of-type {
      width: 23%;
    }
  }
}

/*1024*/

@media screen and (min-width: 900px) and (max-width: 1199px) and (-webkit-min-device-pixel-ratio: 1) {
  .contacts {
    width: 81%;
    #karta,
    #map,
    #map2 {
      width: 540px;
    }
    &__txt {
      font-size: 18px;
      width: 70%;
    }
    &__text,
    &__link {
      font-size: 16px;
    }
    &__head {
      font-size: 30px;
    }
    .contacts__col:last-of-type {
      width: 30%;
    }
  }
}

/*800*/

@media screen and (min-width: 799px) and (max-width: 899px) and (-webkit-min-device-pixel-ratio: 1) {
  .contacts {
    width: 85%;
    margin: 25px auto 45px;
    #karta,
    #map,
    #map2 {
      width: 400px;
    }
    &__txt {
      font-size: 18px;
      width: 90%;
    }
    &__text,
    &__link {
      font-size: 16px;
    }
    &__head {
      font-size: 30px;
    }
    .contacts__col:last-of-type {
      width: 34%;
    }
  }
}

/*768*/

@media screen and (min-width: 601px) and (max-width: 798px) and (-webkit-min-device-pixel-ratio: 1) {
  .contacts {
    width: 85%;
    margin: 25px auto 45px;
    #karta,
    #map,
    #map2 {
      width: 400px;
    }
    &__txt {
      font-size: 18px;
      width: 90%;
    }
    &__text,
    &__link {
      font-size: 16px;
    }
    &__head {
      font-size: 30px;
    }
    .contacts__col:last-of-type {
      width: 35%;
    }
  }
}

/*600*/

@media screen and (min-width: 600px) and (max-width: 699px) and (-webkit-min-device-pixel-ratio: 1) {
  .contacts {
    width: 85%;
    margin: 25px auto 45px;
    #karta,
    #map,
    #map2 {
      width: 100%;
    }
    &__txt {
      font-size: 18px;
      margin: 0 50px 45px;
    }
    &__text,
    &__link {
      font-size: 16px;
    }
    &__head {
      font-size: 30px;
      margin: 0 50px 73px;
    }
    &__row {
      display: block;
    }
    &__col {
      &:last-of-type {
        margin-top: 35px;
        padding: 0 50px;
        width: 100% !important;
      }
    }
  }
}

/*414*/

@media screen and (min-width: 410px) and (max-width: 598px) and (-webkit-min-device-pixel-ratio: 1) {
  .contacts {
    width: 85%;
    margin: 25px auto 45px;
    #karta,
    #map,
    #map2 {
      width: 100%;
    }
    &__txt {
      font-size: 16px;
      margin: 0 30px 45px;
      width: 90%;
    }
    &__text,
    &__link {
      font-size: 16px;
    }
    &__head {
      font-size: 30px;
      margin: 0 30px 45px;
      &::after {
        bottom: -22px;
      }
    }
    &__row {
      display: block;
    }
    &__col {
      &:last-of-type {
        margin-top: 35px;
        padding: 0 30px;
        width: 100% !important;
      }
    }
  }
}

/*384*/

@media screen and (min-width: 383px) and (max-width: 409px) and (-webkit-min-device-pixel-ratio: 1) {
  .contacts {
    width: 85%;
    margin: 25px auto 45px;
    #karta,
    #map,
    #map2 {
      width: 100%;
    }
    &__txt {
      font-size: 16px;
      margin: 0 30px 45px;
      width: 90%;
    }
    &__text,
    &__link {
      font-size: 16px;
    }
    &__head {
      font-size: 30px;
      margin: 0 30px 45px;
      &::after {
        bottom: -22px;
      }
    }
    &__row {
      display: block;
    }
    &__col {
      &:last-of-type {
        margin-top: 35px;
        padding: 0 30px;
        width: 100% !important;
      }
    }
  }
}

/*375*/

@media screen and (min-width: 370px) and (max-width: 382px) and (-webkit-min-device-pixel-ratio: 1) {
  .contacts {
    width: 85%;
    margin: 25px auto 45px;
    #karta,
    #map,
    #map2 {
      width: 100%;
    }
    &__txt {
      font-size: 16px;
      margin: 0 30px 45px;
      width: 90%;
    }
    &__text,
    &__link {
      font-size: 16px;
    }
    &__head {
      font-size: 30px;
      margin: 0 30px 45px;
      &::after {
        bottom: -22px;
      }
    }
    &__row {
      display: block;
    }
    &__col {
      &:last-of-type {
        margin-top: 35px;
        padding: 0 30px;
        width: 100% !important;
      }
    }
  }
}

/*360*/

@media screen and (min-width: 355px) and (max-width: 369px) and (-webkit-min-device-pixel-ratio: 1) {
  .contacts {
    width: 85%;
    margin: 25px auto 45px;
    #karta,
    #map,
    #map2 {
      width: 100%;
    }
    &__txt {
      font-size: 16px;
      margin: 0 0 45px;
      width: 90%;
    }
    &__text,
    &__link {
      font-size: 16px;
    }
    &__head {
      font-size: 30px;
      margin: 0 0 45px;
      &::after {
        bottom: -22px;
      }
    }
    &__row {
      display: block;
    }
    &__col {
      &:last-of-type {
        margin-top: 35px;
        padding: 0 0;
        width: 100% !important;
      }
    }
  }
}

/*320*/

@media screen and (max-width: 350px) and (-webkit-min-device-pixel-ratio: 1) {
  .contacts {
    width: 85%;
    margin: 25px auto 45px;
    #karta,
    #map,
    #map2 {
      width: 100%;
    }
    &__txt {
      font-size: 16px;
      margin: 0 0 45px;
      width: 90%;
    }
    &__text,
    &__link {
      font-size: 16px;
    }
    &__head {
      font-size: 30px;
      margin: 0 0 45px;
      &::after {
        bottom: -22px;
      }
    }
    &__row {
      display: block;
    }
    &__col {
      &:last-of-type {
        margin-top: 35px;
        padding: 0 0;
        width: 100% !important;
      }
    }
  }
}
