.main-nav {
  width: 50%;
  height: 89px;
  &__list {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    list-style-type: none;
    height: 100%;
    align-items: center;
  }
  &__link {
    font-size: 20px;
    font-weight: 300;
    font-family: $font-family-base;
    color: #3B5171;
    cursor: pointer;
    position: relative;
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-bottom: 2px solid #3B5171;
      transition: transform 0.2s;
    }
    &:before {
      border-width: 0 2px;
      transform: scale3d(1, 0, 1);
      transform-origin: 0 50%;
    }
    &:after {
      border-width: 2px 0;
      transform: scale3d(0, 1, 1);
      transform-origin: 0 0;
      transition-delay: 0.2s;
    }
    &:hover {
      color: #3B5171;
      &:before,
      &:after {
        transform: scale3d(1, 1, 1);
      }
    }
  }
  &__dropRow {
    width: 55%;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }
  &__item {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    &--active,
    &:hover {
      .main-nav__link {
        color: #3B5171;
        &:before,
        &:after {
          transform: scale3d(1, 1, 1);
        }
      }
    }
    &--drop {
      position: unset;
    }
  }
  &__dropLink {
    width: 100%;
    a {
      border: none !important;
      &:before,
      &:after {
        border: none !important;
      }
    }
    span {
      display: inline;
      img {
        filter: brightness(0) invert(1);
        max-width: 6px;
        margin-left: 10px;
        margin-top: -4px;
        transition: .5s;
      }
    }
  }
  .rotate {
    transform: rotate(90deg);
    transition: .5s;
  }
  &__drop {
    visibility: hidden;
    /* hides sub-menu */
    opacity: 0;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    transform: translateY(-2em);
    z-index: -1;
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
    position: absolute;
    background-color: #3B5171;
    z-index: 5 !important;
    top: 105px;
    width: 100%;
    ul {
      list-style-type: none;
      padding: 32px 12px;
    }
    li {
      display: block;
      position: relative;
      &:hover {
        a {
          color: #fff;
          &:before,
          &:after {
            transform: scale3d(1, 1, 1);
          }
        }
      }
      a {
        color: #fff;
        font-size: 1.28571em;
        font-weight: 200;
        line-height: 1.7;
        cursor: pointer;
        position: relative;
        &:before,
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-bottom: 2px solid #fff;
          transition: transform 0.2s;
        }
        &:before {
          border-width: 0 2px;
          transform: scale3d(1, 0, 1);
          transform-origin: 0 50%;
        }
        &:after {
          border-width: 2px 0;
          transform: scale3d(0, 1, 1);
          transform-origin: 0 0;
          transition-delay: 0.2s;
        }
      }
    }
  }
  &__item:focus .main-nav__drop,
  &__item:focus-within .main-nav__drop,
  &__item:hover .main-nav__drop {
    visibility: visible;
    /* shows sub-menu */
    opacity: 1;
    z-index: 1;
    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;
    /* this removes the transition delay so the menu will be visible while the other styles transition */
  }
  &__inner {
    position: relative;
    background-color: #3B5171;
    padding: 0 20px;
    z-index: 5 !important;
    top: 0;
    width: 100%;
    left: 0;
    overflow: hidden;
    display: none;
    ul {
      padding: 10px 10px !important;
    }
    li {
      display: block;
    }
    &--open {
      height: auto;
      padding: 10px;
      overflow: initial;
      transition: .5s;
    }
  }
}

/*1600*/

@media screen and (min-width: 1501px) and (max-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .main-nav {
    &__link {
      font-size: 18px;
    }
  }
}

/*1440*/

@media screen and (min-width: 1401px) and (max-width: 1500px) and (-webkit-min-device-pixel-ratio: 1) {
  .main-nav {
    &__link {
      font-size: 16px;
    }
    &__dropRow {
      width: 65%;
    }
  }
}

/*1366*/

@media screen and (min-width: 1301px) and (max-width: 1400px) and (-webkit-min-device-pixel-ratio: 1) {
  .main-nav {
    width: 48%;
    &__link {
      font-size: 16px;
    }
    &__dropRow {
      width: 65%;
    }
  }
}

/*1280*/

@media screen and (min-width: 1200px) and (max-width: 1300px) and (-webkit-min-device-pixel-ratio: 1) {
  .main-nav {
    width: 44%;
    margin-left: -4%;
    &__link {
      font-size: 16px;
    }
    &__dropRow {
      width: 70%;
    }
  }
}

/*1024*/

@media screen and (min-width: 900px) and (max-width: 1199px) and (-webkit-min-device-pixel-ratio: 1) {
  .main-nav {
    margin-top: 18px;
    width: 100%;
    padding: 23px 100px 20px;
    border-top: 1px solid rgba(112, 112, 112, 0.1);
    &__link {
      font-size: 16px;
    }
    &__item {
      &::after {
        display: none;
      }
    }
    &__drop {
      top: 172px;
    }
    &__dropRow {
      width: 82%;
    }
  }
}

/*800*/

@media screen and (min-width: 799px) and (max-width: 899px) and (-webkit-min-device-pixel-ratio: 1) {
  .main-nav {
    margin-top: 18px;
    width: 100%;
    padding: 23px 50px 20px;
    border-top: 1px solid rgba(112, 112, 112, 0.1);
    &__link {
      font-size: 16px;
    }
    &__item {
      &::after {
        display: none;
      }
    }
    &__drop {
      top: 172px;
      li {
        margin-right: 15%;
      }
      ul {
        padding: 32px 0px;
      }
    }
    &__dropRow {
      width: 91%;
    }
  }
}

/*768*/

@media screen and (min-width: 601px) and (max-width: 798px) and (-webkit-min-device-pixel-ratio: 1) {
  .main-nav {
    margin-top: 18px;
    width: 100%;
    padding: 23px 50px 20px;
    border-top: 1px solid rgba(112, 112, 112, 0.1);
    &__link {
      font-size: 16px;
    }
    &__item {
      &::after {
        display: none;
      }
    }
    &__drop {
      top: 172px;
      li {
        margin-right: 15%;
      }
      ul {
        padding: 32px 0px;
      }
    }
    &__dropRow {
      width: 91%;
    }
  }
}

/*600*/

@media screen and (min-width: 600px) and (max-width: 699px) and (-webkit-min-device-pixel-ratio: 1) {
  .main-nav {
    margin-top: 18px;
    width: 100%;
    padding: 23px 50px 20px;
    border-top: 1px solid rgba(112, 112, 112, 0.1);
    &__link {
      font-size: 16px;
    }
    &__item {
      &::after {
        display: none;
      }
    }
    &__drop {
      top: 172px;
      li {
        margin-right: 15%;
        a {
          font-size: 13px;
        }
      }
      ul {
        padding: 32px 0px;
      }
    }
    &__dropRow {
      width: 91%;
    }
  }
}

/*414*/

@media screen and (min-width: 410px) and (max-width: 598px) and (-webkit-min-device-pixel-ratio: 1) {
  .main-nav {
    margin-top: 18px;
    width: 100%;
    padding: 10px 30px 10px;
    height: auto;
    border-top: 1px solid rgba(112, 112, 112, 0.1);
    &__link {
      font-size: 15px;
    }
    &__item {
      &::after {
        display: none;
      }
      &--drop {
        display: none;
      }
    }
  }
}

/*384*/

@media screen and (min-width: 383px) and (max-width: 409px) and (-webkit-min-device-pixel-ratio: 1) {
  .main-nav {
    margin-top: 18px;
    width: 100%;
    padding: 10px 15px 10px;
    height: auto;
    border-top: 1px solid rgba(112, 112, 112, 0.1);
    &__link {
      font-size: 15px;
    }
    &__item {
      &::after {
        display: none;
      }
      &--drop {
        display: none;
      }
    }
  }
}

/*375*/

@media screen and (min-width: 370px) and (max-width: 382px) and (-webkit-min-device-pixel-ratio: 1) {
  .main-nav {
    margin-top: 18px;
    width: 100%;
    padding: 10px 15px 10px;
    height: auto;
    border-top: 1px solid rgba(112, 112, 112, 0.1);
    &__link {
      font-size: 15px;
    }
    &__item {
      &::after {
        display: none;
      }
      &--drop {
        display: none;
      }
    }
  }
}

/*360*/

@media screen and (min-width: 355px) and (max-width: 369px) and (-webkit-min-device-pixel-ratio: 1) {
  .main-nav {
    margin-top: 18px;
    width: 100%;
    padding: 10px 15px 10px;
    height: auto;
    border-top: 1px solid rgba(112, 112, 112, 0.1);
    &__link {
      font-size: 15px;
    }
    &__item {
      &::after {
        display: none;
      }
      &--drop {
        display: none;
      }
    }
  }
}

/*320*/

@media screen and (max-width: 350px) and (-webkit-min-device-pixel-ratio: 1) {
  .main-nav {
    margin-top: 18px;
    width: 100%;
    padding: 10px 15px 10px;
    height: auto;
    border-top: 1px solid rgba(112, 112, 112, 0.1);
    &__link {
      font-size: 13px;
    }
    &__item {
      &::after {
        display: none;
      }
      &--drop {
        display: none;
      }
    }
  }
}
