.bs-preloader {
    width: 118px;
    position: fixed;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    &__wrp {
        position: fixed;
        width: 100%;
        height: 100%;
        background: white;
        z-index: 999;
    }
    &__icon {
        max-width: 118px;
        max-height: 72px;
    }
    &__progress {
        position: relative;
        height: 2px;
        display: block;
        width: 100%;
        background-color: $brand-primary;
        margin: 15px 0 0 0;
        overflow: hidden;
    }
    &__indeterminate {
        background-color: #D8E6E7;
        &:before {
            content: '';
            position: absolute;
            background-color: inherit;
            top: 0;
            left: 0;
            bottom: 0;
            will-change: left, right;
            animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
        }
        &:after {
            content: '';
            position: absolute;
            background-color: inherit;
            top: 0;
            left: 0;
            bottom: 0;
            will-change: left, right;
            animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
            animation-delay: 1.15s;
        }
    }
}
@keyframes indeterminate {
    0% {
        left: -35%;
        right: 100%
    }
    60% {
        left: 100%;
        right: -90%
    }
    100% {
        left: 100%;
        right: -90%
    }
}

@-webkit-keyframes indeterminate-short {
    0% {
        left: -200%;
        right: 100%
    }
    60% {
        left: 107%;
        right: -8%
    }
    100% {
        left: 107%;
        right: -8%
    }
}

@keyframes indeterminate-short {
    0% {
        left: -200%;
        right: 100%
    }
    60% {
        left: 107%;
        right: -8%
    }
    100% {
        left: 107%;
        right: -8%
    }
}
