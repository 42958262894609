// Body
$body-bg: #fff;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #000;
$brand-info: #b8eef1;
$brand-success: #28a43b;
$brand-warning: #e3debb;
$brand-danger: #de8ca4;

// Typography

// Font-face example of order
//@font-face {
//    font-family: 'nobileitalic';
//    src: url('nobile_italic-webfont.eot');
//    src: url('nobile_italic-webfont.eot?#iefix') format('embedded-opentype'),
//    url('nobile_italic-webfont.woff') format('woff'),
//    url('nobile_italic-webfont.ttf') format('truetype'),
//    url('nobile_italic-webfont.svg#nobileitalic') format('svg');
//    font-weight: normal;
//    font-style: normal;
//...

// Fonts via Google Font
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&amp;subset=cyrillic");
@import url("https://fonts.googleapis.com/css?family=Lato:100,100i,400,700,700i");

//Fonts locally
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-Thin.ttf');
    font-weight: 100;
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-Light.ttf');
    font-weight: 200;
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-Regular.ttf');
    font-weight: 300;
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-Medium.ttf');
    font-weight: 400;
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-Black.ttf');
    font-weight: 700;
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-Bold.ttf');
    font-weight: 600;
}

$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-base: 'Roboto', sans-serif;
$font-family-az: 'Lato', sans-serif;
$font-size-base: 14px;
$line-height-base: 1.4;
$text-color: #626262;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

// @medias
$screen-xlg: 1600px;
$screen-lg: 1200px;
$screen-md: 992px;
$screen-sm: 768px;
$screen-xs: 480px;