.sidebar {
  width: 0;
  margin-bottom: 46px;
  visibility: hidden;
  overflow: hidden;
  height: 0;
  &__list {
    list-style-type: none;
    padding: 0;
  }
  &__link {
    color: #3B5171;
    font-size: 18px;
    font-family: $font-family-base;
    font-weight: 300;
    padding: 0 0 0 30px;
    width: 97%;
  }
  &__drop {
    display: none;
    list-style-type: disc;
    padding-left: 57px;
    padding-top: 18px;
    li a {
      color: #3B5171;
      font-size: 18px;
      font-family: $font-family-base;
      font-weight: 300;
    }
  }
  &__item {
    padding: 20px 92px 20.5px 30px;
    border: 1px solid rgba(112, 112, 112, 0.1);
    display: flex;
    align-items: center;
    cursor: pointer;
    p {
      margin: 0;
      width: 3%;
    }
    &:not(:last-of-type) {
      border-bottom: none;
    }
    &--under {
      color: #3B5171;
      font-size: 18px;
      font-family: $font-family-base;
      font-weight: 300;
      padding: 20px 0 20.5px 30px;
      display: block;
      p {
        margin-right: 28px;
        display: inline;
      }
    }
    &--drop {
      transition: 1s;
    }
    &--hide {
      display: none;
    }
    &--active {
      background: rgba(59, 81, 113, 0.8);
      color: #fff;
      ul li a {
        color: #fff;
      }
      .sidebar__link {
        color: #fff;
      }
      p {
        img {
          filter: brightness(0) invert(1);
          transform: rotate(90deg);
        }
      }
    }
  }
  &__hidden {
    display: none; // height: 0;
    // visibility: hidden;
    // transition: 1s;
    // &--show {
    //   height: auto;
    //   visibility: visible;
    // }
  }
  &--hide {
    height: 0;
    visibility: hidden;
    transition: 1s;
    .sidebar__hidden--show {
      display: none;
    }
  }
}

/*1600*/

@media screen and (min-width: 1501px) and (max-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .sidebar {}
}

/*1440*/

@media screen and (min-width: 1401px) and (max-width: 1500px) and (-webkit-min-device-pixel-ratio: 1) {
  .sidebar {
    &__link {
      font-size: 16px;
    }
    &__item {
      padding: 18px 50px 18.5px 30px;
    }
  }
}

/*1366*/

@media screen and (min-width: 1301px) and (max-width: 1400px) and (-webkit-min-device-pixel-ratio: 1) {
  .sidebar {
    &__link {
      font-size: 16px;
    }
    &__item {
      padding: 18px 50px 18.5px 30px;
    }
  }
}

/*1280*/

@media screen and (min-width: 1200px) and (max-width: 1300px) and (-webkit-min-device-pixel-ratio: 1) {
  .sidebar {
    &__link {
      font-size: 16px;
    }
    &__item {
      padding: 18px 50px 18.5px 30px;
    }
  }
}

/*1024*/

@media screen and (min-width: 900px) and (max-width: 1199px) and (-webkit-min-device-pixel-ratio: 1) {
  .sidebar {
    width: 100%;
    position: absolute;
    z-index: 10;
    background: #fff;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    transition: 0.5s;
    &__link {
      font-size: 16px;
    }
    &__list {
      margin: 0;
    }
    &__item {
      padding: 18px 50px 18.5px 100px;
    }
    &--show {
      height: auto;
      visibility: visible;
      transition: 0.5s;
    }
    &--hide {
      .sidebar__hidden--show {
        display: block;
      }
    }
  }
}

/*800*/

@media screen and (min-width: 799px) and (max-width: 899px) and (-webkit-min-device-pixel-ratio: 1) {
  .sidebar {
    width: 100%;
    position: absolute;
    z-index: 10;
    background: #fff;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    transition: 0.5s;
    &__link {
      font-size: 16px;
    }
    &__list {
      margin: 0;
    }
    &__item {
      padding: 18px 50px 18.5px 50px;
    }
    &--show {
      height: auto;
      visibility: visible;
      transition: 0.5s;
    }
    &--hide {
      .sidebar__hidden--show {
        display: block;
      }
    }
  }
}

/*768*/

@media screen and (min-width: 601px) and (max-width: 798px) and (-webkit-min-device-pixel-ratio: 1) {
  .sidebar {
    width: 100%;
    position: absolute;
    z-index: 10;
    background: #fff;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    transition: 0.5s;
    &__link {
      font-size: 16px;
    }
    &__list {
      margin: 0;
    }
    &__item {
      padding: 18px 50px 18.5px 50px;
    }
    &--show {
      height: auto;
      visibility: visible;
      transition: 0.5s;
    }
    &--hide {
      .sidebar__hidden--show {
        display: block;
      }
    }
  }
}

/*600*/

@media screen and (min-width: 600px) and (max-width: 699px) and (-webkit-min-device-pixel-ratio: 1) {
  .sidebar {
    width: 100%;
    position: absolute;
    z-index: 10;
    background: #fff;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    transition: 0.5s;
    &__link {
      font-size: 16px;
    }
    &__list {
      margin: 0;
    }
    &__item {
      padding: 18px 50px 18.5px 50px;
    }
    &--show {
      height: auto;
      visibility: visible;
      transition: 0.5s;
    }
    &--hide {
      .sidebar__hidden--show {
        display: block;
      }
    }
  }
}

/*414*/

@media screen and (min-width: 410px) and (max-width: 598px) and (-webkit-min-device-pixel-ratio: 1) {
  .sidebar {
    width: 100%;
    position: absolute;
    z-index: 10;
    background: #fff;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    transition: 0.5s;
    &__link {
      font-size: 16px;
    }
    &__list {
      margin: 0;
    }
    &__item {
      padding: 18px 30px 18.5px 30px;
    }
    &--show {
      height: auto;
      visibility: visible;
      transition: 0.5s;
    }
    &--hide {
      .sidebar__hidden--show {
        display: block;
      }
    }
  }
}

/*384*/

@media screen and (min-width: 383px) and (max-width: 409px) and (-webkit-min-device-pixel-ratio: 1) {
  .sidebar {
    width: 100%;
    position: absolute;
    z-index: 10;
    background: #fff;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    transition: 0.5s;
    &__link {
      font-size: 16px;
    }
    &__list {
      margin: 0;
    }
    &__item {
      padding: 18px 30px 18.5px 30px;
    }
    &--show {
      height: auto;
      visibility: visible;
      transition: 0.5s;
    }
    &--hide {
      .sidebar__hidden--show {
        display: block;
      }
    }
  }
}

/*375*/

@media screen and (min-width: 370px) and (max-width: 382px) and (-webkit-min-device-pixel-ratio: 1) {
  .sidebar {
    width: 100%;
    position: absolute;
    z-index: 10;
    background: #fff;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    transition: 0.5s;
    &__link {
      font-size: 16px;
    }
    &__list {
      margin: 0;
    }
    &__item {
      padding: 18px 30px 18.5px 30px;
    }
    &--show {
      height: auto;
      visibility: visible;
      transition: 0.5s;
    }
    &--hide {
      .sidebar__hidden--show {
        display: block;
      }
    }
  }
}

/*360*/

@media screen and (min-width: 355px) and (max-width: 369px) and (-webkit-min-device-pixel-ratio: 1) {
  .sidebar {
    width: 100%;
    position: absolute;
    z-index: 10;
    background: #fff;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    transition: 0.5s;
    &__link {
      font-size: 16px;
    }
    &__list {
      margin: 0;
    }
    &__item {
      padding: 18px 30px 18.5px 30px;
    }
    &--show {
      height: auto;
      visibility: visible;
      transition: 0.5s;
    }
    &--hide {
      .sidebar__hidden--show {
        display: block;
      }
    }
  }
}

/*320*/

@media screen and (max-width: 350px) and (-webkit-min-device-pixel-ratio: 1) {
  .sidebar {
    width: 100%;
    position: absolute;
    z-index: 10;
    background: #fff;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    transition: 0.5s;
    &__link {
      font-size: 16px;
    }
    &__list {
      margin: 0;
    }
    &__item {
      padding: 18px 30px 18.5px 20px;
    }
    &--show {
      height: auto;
      visibility: visible;
      transition: 0.5s;
    }
    &--hide {
      .sidebar__hidden--show {
        display: block;
      }
    }
  }
}
