.footer {
  background: #3B5171;
  padding-bottom: 50px;
  &__container {
    width: 80%;
    margin: auto;
  }
  &__head {
    font-family: $font-family-base;
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    margin: 51px 0 47px;
    position: relative;
    &::after {
      content: "";
      width: 38px;
      height: 1px;
      background: #fff;
      position: absolute;
      left: 0;
      bottom: -19.5px;
    }
  }
  &__txt {
    font-family: $font-family-base;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    margin: 0;
    color: #fff;
    &--mob {
      display: none;
    }
    a {
      color: #fff;
      &:hover,
      &:focus {
        color: #fff;
        outline: none;
        text-decoration: none;
      }
    }
  }
  &__kok {
    font-family: $font-family-base;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    margin: 30px 0 70px;
    color: #fff;
  }
  &__text {
    font-family: $font-family-base;
    font-size: 16px;
    font-weight: 300;
    line-height: 1;
    color: #B1B1B1;
    &--accept {
      margin-top: 124px;
    }
    &--mob {
      display: none;
    }
  }
  &__form {
    position: relative;
  }
  &__input {
    width: 100%;
    background: rgba(255, 255, 255, 0.17);
    height: 32px;
    border: 1px solid rgba(250, 250, 250, 0.2);
    font-family: $font-family-base;
    font-size: 16px;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.49);
    padding: 0 20px;
  }
  &__send {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    background: #FFC400 url(../images/sub.png);
    background-repeat: no-repeat;
    font-size: 0;
    height: 31px;
    width: 41px;
    background-position: center;
  }
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    &:first-of-type {
      margin-top: 187px;
    }
  }
  &__office {
    width: 26%;
    float: left;
    &--sec {
      margin-top: 121px;
    }
  }
  &__mood {
    width: 18%;
    float: right;
  }
  &__subscribe {
    width: 24%;
    .footer__head {
      &::after {
        display: none;
      }
    }
  }
}

/*1600*/

@media screen and (min-width: 1501px) and (max-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .footer {
    &__subscribe {
      width: 27%;
    }
    &__office {
      width: 26%;
    }
    &__mood {
      width: 14%;
    }
    &__text {
      &--accept {
        margin-top: 196px;
      }
    }
    &__row {
      &:first-of-type {
        margin-top: 257px;
      }
    }
  }
}

/*1440*/

@media screen and (min-width: 1401px) and (max-width: 1500px) and (-webkit-min-device-pixel-ratio: 1) {
  .footer {
    &__subscribe {
      width: 27%;
    }
    &__office {
      width: 28%;
    }
    &__mood {
      width: 14%;
    }
    &__text {
      &--accept {
        margin-top: 196px;
      }
    }
    &__row {
      &:first-of-type {
        margin-top: 257px;
      }
    }
  }
}

/*1366*/

@media screen and (min-width: 1301px) and (max-width: 1400px) and (-webkit-min-device-pixel-ratio: 1) {
  .footer {
    &__subscribe {
      width: 27%;
    }
    &__container {
      width: 86%;
    }
    &__office {
      width: 28%;
    }
    &__mood {
      width: 14%;
    }
    &__text {
      &--accept {
        margin-top: 196px;
      }
    }
    &__row {
      &:first-of-type {
        margin-top: 257px;
      }
    }
  }
}

/*1280*/

@media screen and (min-width: 1200px) and (max-width: 1300px) and (-webkit-min-device-pixel-ratio: 1) {
  .footer {
    &__subscribe {
      width: 27%;
    }
    &__container {
      width: 92%;
    }
    &__office {
      width: 28%;
    }
    &__mood {
      width: 15%;
    }
    &__text {
      &--accept {
        margin-top: 196px;
      }
    }
    &__row {
      &:first-of-type {
        margin-top: 259px;
      }
    }
  }
}

/*1024*/

@media screen and (min-width: 900px) and (max-width: 1199px) and (-webkit-min-device-pixel-ratio: 1) {
  .footer {
    &__subscribe {
      width: 27%;
    }
    &__text {
      &--accept {
        margin-top: 196px;
      }
    }
    &__office {
      width: 39%;
    }
    &__row {
      &:first-of-type {
        margin-top: 259px;
      }
    }
  }
}

/*800*/

@media screen and (min-width: 799px) and (max-width: 899px) and (-webkit-min-device-pixel-ratio: 1) {
  .footer {
    &__subscribe {
      width: 32%;
    }
    &__text {
      &--accept {
        margin-top: 196px;
      }
    }
    &__container {
      width: 92%;
    }
    &__row {
      &:first-of-type {
        margin-top: 259px;
      }
    }
    &__office {
      width: 40%;
      padding: 0 30px 0 0;
    }
    &__mood {
      width: 20%;
    }
  }
}

/*768*/

@media screen and (min-width: 601px) and (max-width: 798px) and (-webkit-min-device-pixel-ratio: 1) {
  .footer {
    &__subscribe {
      width: 32%;
    }
    &__text {
      &--accept {
        margin-top: 196px;
      }
    }
    &__container {
      width: 92%;
    }
    &__row {
      &:first-of-type {
        margin-top: 259px;
      }
    }
    &__office {
      width: 40%;
      padding: 0 30px 0 0;
    }
    &__mood {
      width: 20%;
    }
  }
}

/*600*/

@media screen and (min-width: 600px) and (max-width: 699px) and (-webkit-min-device-pixel-ratio: 1) {
  .footer {
    display: block;
    &__subscribe {
      width: 100%;
      .footer__head {
        padding: 64px 0 25px;
      }
    }
    &__text {
      display: none;
      &--accept {
        margin: 60px 0 30px;
      }
      &--mob {
        display: block;
      }
    }
    &__row {
      margin-top: 45px;
      &:first-of-type {
        margin-top: 0;
      }
    }
    &__kok {
      margin-bottom: 0;
    }
    &__mood {
      width: 100%;
    }
    &__office {
      width: 100%;
      &--sec {
        margin-top: 0;
      }
    }
    &__txt {
      font-size: 15px;
      &--mob {
        display: none;
      }
    }
    &__head {
      font-size: 18px;
      margin: 0;
      padding: 40px 0 50px;
      &::after {
        bottom: 23.5px;
      }
    }
  }
}

/*414*/

@media screen and (min-width: 410px) and (max-width: 598px) and (-webkit-min-device-pixel-ratio: 1) {
  .footer {
    padding: 0px 30px 65px;
    display: block;
    &__subscribe {
      width: 100%;
      .footer__head {
        padding: 64px 0 25px;
      }
    }
    &__text {
      display: none;
      &--accept {
        margin: 60px 0 30px;
      }
      &--mob {
        display: block;
      }
    }
    &__row {
      margin-top: 45px;
      &:first-of-type {
        margin-top: 0;
      }
    }
    &__kok {
      margin-bottom: 0;
    }
    &__mood {
      width: 100%;
    }
    &__office {
      width: 100%;
      &--sec {
        margin-top: 0;
      }
    }
    &__txt {
      font-size: 15px;
      &--mob {
        display: none;
      }
    }
    &__head {
      font-size: 18px;
      margin: 0;
      padding: 51px 0 47px;
      &::after {
        bottom: 23.5px;
      }
    }
  }
}

/*384*/

@media screen and (min-width: 383px) and (max-width: 409px) and (-webkit-min-device-pixel-ratio: 1) {
  .footer {
    padding: 0px 30px 65px;
    display: block;
    &__subscribe {
      width: 100%;
      .footer__head {
        padding: 64px 0 25px;
      }
    }
    &__text {
      display: none;
      &--accept {
        margin: 60px 0 30px;
      }
      &--mob {
        display: block;
      }
    }
    &__row {
      margin-top: 45px;
      &:first-of-type {
        margin-top: 0;
      }
    }
    &__kok {
      margin-bottom: 0;
    }
    &__mood {
      width: 100%;
    }
    &__office {
      width: 100%;
      &--sec {
        margin-top: 0;
      }
    }
    &__txt {
      font-size: 15px;
      &--mob {
        display: none;
      }
    }
    &__head {
      font-size: 18px;
      margin: 0;
      padding: 51px 0 47px;
      &::after {
        bottom: 23.5px;
      }
    }
  }
}

/*375*/

@media screen and (min-width: 370px) and (max-width: 382px) and (-webkit-min-device-pixel-ratio: 1) {
  .footer {
    padding: 0px 10px 65px;
    display: block;
    &__subscribe {
      width: 100%;
      .footer__head {
        padding: 64px 0 25px;
      }
    }
    &__text {
      display: none;
      &--accept {
        margin: 60px 0 30px;
      }
      &--mob {
        display: block;
      }
    }
    &__row {
      margin-top: 45px;
      &:first-of-type {
        margin-top: 0;
      }
    }
    &__kok {
      margin-bottom: 0;
    }
    &__mood {
      width: 100%;
    }
    &__office {
      width: 100%;
      &--sec {
        margin-top: 0;
      }
    }
    &__txt {
      font-size: 15px;
      &--mob {
        display: none;
      }
    }
    &__head {
      font-size: 18px;
      margin: 0;
      padding: 51px 0 47px;
      &::after {
        bottom: 23.5px;
      }
    }
  }
}

/*360*/

@media screen and (min-width: 355px) and (max-width: 369px) and (-webkit-min-device-pixel-ratio: 1) {
  .footer {
    padding: 0px 10px 20px;
    display: block;
    &__subscribe {
      width: 100%;
      .footer__head {
        padding: 64px 0 25px;
      }
    }
    &__text {
      display: none;
      &--accept {
        margin: 60px 0 30px;
      }
      &--mob {
        display: block;
      }
    }
    &__row {
      margin-top: 45px;
      &:first-of-type {
        margin-top: 0;
      }
    }
    &__kok {
      margin-bottom: 0;
    }
    &__mood {
      width: 100%;
    }
    &__office {
      width: 100%;
      &--sec {
        margin-top: 0;
      }
    }
    &__txt {
      font-size: 15px;
      &--mob {
        display: none;
      }
    }
    &__head {
      font-size: 18px;
      margin: 0;
      padding: 30px 0 47px;
      &::after {
        bottom: 23.5px;
      }
    }
    &__container {
      width: 87%;
    }
  }
}

/*320*/

@media screen and (max-width: 350px) and (-webkit-min-device-pixel-ratio: 1) {
  .footer {
    padding: 0px 10px 20px;
    display: block;
    &__subscribe {
      width: 100%;
      .footer__head {
        padding: 64px 0 25px;
      }
    }
    &__text {
      display: none;
      &--accept {
        margin: 60px 0 30px;
      }
      &--mob {
        display: block;
      }
    }
    &__row {
      margin-top: 45px;
      &:first-of-type {
        margin-top: 0;
      }
    }
    &__kok {
      margin-bottom: 0;
    }
    &__mood {
      width: 100%;
    }
    &__office {
      width: 100%;
      &--sec {
        margin-top: 0;
      }
    }
    &__txt {
      font-size: 15px;
      &--mob {
        display: none;
      }
    }
    &__head {
      font-size: 18px;
      margin: 0;
      padding: 30px 0 47px;
      &::after {
        bottom: 23.5px;
      }
    }
    &__container {
      width: 87%;
    }
  }
}
