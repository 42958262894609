.about {
  &__row {
    display: flex;
  }
  &__container {
    margin: 75px auto 105px;
    width: 65%;
    .about__row {
      margin: 0 0 90px 0;
      justify-content: space-between;
    }
  }
  &__col {
    width: 46%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    &--img {
      align-items: flex-end;
    }
  }
  &__txt {
    font-family: $font-family-base;
    font-size: 20px;
    font-weight: 300;
    width: 100%;
    color: #3B5171;
    margin: 0;
  }
  &__head {
    font-family: $font-family-base;
    font-size: 36px;
    font-weight: 300;
    color: #3B5171;
    margin: 0 0 38px;
    position: relative;
    &::after {
      content: "";
      width: 52.4px;
      height: 2.08px;
      background: #FFC400;
      position: absolute;
      left: 0;
      bottom: -18px;
    }
  }
}

/*1600*/

@media screen and (min-width: 1501px) and (max-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .about {
    &__txt {
      font-size: 18px;
    }
    &__head {
      font-size: 32px;
    }
    &__container {
      width: 68%;
    }
  }
}

/*1440*/

@media screen and (min-width: 1401px) and (max-width: 1500px) and (-webkit-min-device-pixel-ratio: 1) {
  .about {
    &__txt {
      font-size: 16px;
    }
    &__head {
      font-size: 32px;
    }
    &__container {
      width: 80%;
      margin-bottom: 50px;
    }
    &__row {
      margin: 0;
    }
  }
}

/*1366*/

@media screen and (min-width: 1301px) and (max-width: 1400px) and (-webkit-min-device-pixel-ratio: 1) {
  .about {
    &__txt {
      font-size: 16px;
    }
    &__head {
      font-size: 28px;
    }
    &__container {
      width: 82%;
      margin-bottom: 30px;
    }
    &__row {
      margin: 0;
    }
  }
}

/*1280*/

@media screen and (min-width: 1200px) and (max-width: 1300px) and (-webkit-min-device-pixel-ratio: 1) {
  .about {
    &__txt {
      font-size: 16px;
    }
    &__head {
      font-size: 28px;
    }
    &__container {
      width: 92%;
      margin-bottom: 30px;
    }
    &__row {
      margin: 0;
    }
  }
}

/*1024*/

@media screen and (min-width: 900px) and (max-width: 1199px) and (-webkit-min-device-pixel-ratio: 1) {
  .about {
    &__txt {
      font-size: 16px;
    }
    &__head {
      font-size: 28px;
    }
    &__container {
      width: 81%;
      margin: 55px auto 0;
      .about__row {
        margin: 0 0 90px;
      }
    }
    &__row {
      margin: 0;
      width: 100%;
    }
  }
}

/*800*/

@media screen and (min-width: 799px) and (max-width: 899px) and (-webkit-min-device-pixel-ratio: 1) {
  .about {
    &__txt {
      font-size: 15px;
    }
    &__head {
      font-size: 25px;
      margin: 0 0 16px;
      &::after {
        bottom: -10px;
      }
    }
    &__container {
      width: 88%;
      margin: 55px auto 0;
      .about__row {
        margin: 0 0 70px;
      }
    }
    &__col {
      width: 48%;
    }
    &__row {
      margin: 0;
      width: 100%;
    }
  }
}

/*768*/

@media screen and (min-width: 601px) and (max-width: 798px) and (-webkit-min-device-pixel-ratio: 1) {
  .about {
    &__txt {
      font-size: 15px;
    }
    &__head {
      font-size: 25px;
      margin: 0 0 16px;
      &::after {
        bottom: -10px;
      }
    }
    &__container {
      width: 88%;
      margin: 55px auto 0;
      .about__row {
        margin: 0 0 50px;
      }
    }
    &__col {
      width: 48%;
      img {
        height: 86%;
      }
    }
    &__row {
      margin: 0;
      width: 100%;
    }
  }
}

/*600*/

@media screen and (min-width: 600px) and (max-width: 699px) and (-webkit-min-device-pixel-ratio: 1) {
  .about {
    &__txt {
      font-size: 15px;
    }
    &__head {
      font-size: 25px;
      margin: 22px 0 38px;
      &::after {
        bottom: -18px;
      }
    }
    &__container {
      width: 83%;
      margin: 25px auto 0;
      .about__row {
        margin: 0 0 33px;
        flex-direction: column;
      }
    }
    &__col {
      width: 100%;
      &--img {
        align-items: flex-start;
      }
      img {
        width: 100%;
      }
    }
    &__row {
      margin: 0;
      width: 100%;
      &:first-of-type {
        .about__col {
          &:first-of-type {
            order: 1;
          }
          &:last-of-type {
            order: 0;
          }
        }
      }
      &:nth-of-type(2) {
        .about__col {
          &:first-of-type {
            order: 0;
          }
          &:last-of-type {
            order: 1;
          }
        }
      }
      &:nth-of-type(3) {
        .about__col {
          &:first-of-type {
            order: 1;
          }
          &:last-of-type {
            order: 0;
          }
        }
      }
      &:nth-of-type(4) {
        .about__col {
          &:first-of-type {
            order: 0;
          }
          &:last-of-type {
            order: 1;
          }
        }
      }
    }
  }
}

/*414*/

@media screen and (min-width: 410px) and (max-width: 598px) and (-webkit-min-device-pixel-ratio: 1) {
  .about {
    &__txt {
      font-size: 15px;
    }
    &__head {
      font-size: 25px;
      margin: 22px 0 38px;
      &::after {
        bottom: -18px;
      }
    }
    &__container {
      width: 85%;
      margin: 35px auto 0;
      .about__row {
        margin: 0 0 33px;
        flex-direction: column;
      }
    }
    &__col {
      width: 100%;
      &--img {
        align-items: flex-start;
      }
      img {
        width: 100%;
      }
    }
    &__row {
      margin: 0;
      width: 100%;
      &:first-of-type {
        .about__col {
          &:first-of-type {
            order: 1;
          }
          &:last-of-type {
            order: 0;
          }
        }
      }
      &:nth-of-type(2) {
        .about__col {
          &:first-of-type {
            order: 0;
          }
          &:last-of-type {
            order: 1;
          }
        }
      }
      &:nth-of-type(3) {
        .about__col {
          &:first-of-type {
            order: 1;
          }
          &:last-of-type {
            order: 0;
          }
        }
      }
      &:nth-of-type(4) {
        .about__col {
          &:first-of-type {
            order: 0;
          }
          &:last-of-type {
            order: 1;
          }
        }
      }
    }
  }
}

/*384*/

@media screen and (min-width: 383px) and (max-width: 409px) and (-webkit-min-device-pixel-ratio: 1) {
  .about {
    &__txt {
      font-size: 15px;
    }
    &__head {
      font-size: 25px;
      margin: 22px 0 38px;
      &::after {
        bottom: -18px;
      }
    }
    &__container {
      width: 85%;
      margin: 35px auto 0;
      .about__row {
        margin: 0 0 33px;
        flex-direction: column;
      }
    }
    &__col {
      width: 100%;
      &--img {
        align-items: flex-start;
      }
      img {
        width: 100%;
      }
    }
    &__row {
      margin: 0;
      width: 100%;
      &:first-of-type {
        .about__col {
          &:first-of-type {
            order: 1;
          }
          &:last-of-type {
            order: 0;
          }
        }
      }
      &:nth-of-type(2) {
        .about__col {
          &:first-of-type {
            order: 0;
          }
          &:last-of-type {
            order: 1;
          }
        }
      }
      &:nth-of-type(3) {
        .about__col {
          &:first-of-type {
            order: 1;
          }
          &:last-of-type {
            order: 0;
          }
        }
      }
      &:nth-of-type(4) {
        .about__col {
          &:first-of-type {
            order: 0;
          }
          &:last-of-type {
            order: 1;
          }
        }
      }
    }
  }
}

/*375*/

@media screen and (min-width: 370px) and (max-width: 382px) and (-webkit-min-device-pixel-ratio: 1) {
  .about {
    &__txt {
      font-size: 15px;
    }
    &__head {
      font-size: 25px;
      margin: 22px 0 38px;
      &::after {
        bottom: -18px;
      }
    }
    &__container {
      width: 85%;
      margin: 35px auto 0;
      .about__row {
        margin: 0 0 33px;
        flex-direction: column;
      }
    }
    &__col {
      width: 100%;
      &--img {
        align-items: flex-start;
      }
      img {
        width: 100%;
      }
    }
    &__row {
      margin: 0;
      width: 100%;
      &:first-of-type {
        .about__col {
          &:first-of-type {
            order: 1;
          }
          &:last-of-type {
            order: 0;
          }
        }
      }
      &:nth-of-type(2) {
        .about__col {
          &:first-of-type {
            order: 0;
          }
          &:last-of-type {
            order: 1;
          }
        }
      }
      &:nth-of-type(3) {
        .about__col {
          &:first-of-type {
            order: 1;
          }
          &:last-of-type {
            order: 0;
          }
        }
      }
      &:nth-of-type(4) {
        .about__col {
          &:first-of-type {
            order: 0;
          }
          &:last-of-type {
            order: 1;
          }
        }
      }
    }
  }
}

/*360*/

@media screen and (min-width: 355px) and (max-width: 369px) and (-webkit-min-device-pixel-ratio: 1) {
  .about {
    &__txt {
      font-size: 15px;
    }
    &__head {
      font-size: 25px;
      margin: 22px 0 38px;
      &::after {
        bottom: -18px;
      }
    }
    &__container {
      width: 85%;
      margin: 55px auto 50px;
      .about__row {
        margin: 0 0 33px;
        flex-direction: column;
      }
    }
    &__col {
      width: 100%;
      &--img {
        align-items: flex-start;
      }
      img {
        width: 100%;
      }
    }
    &__row {
      margin: 0;
      width: 100%;
      &:first-of-type {
        .about__col {
          &:first-of-type {
            order: 1;
          }
          &:last-of-type {
            order: 0;
          }
        }
      }
      &:nth-of-type(2) {
        .about__col {
          &:first-of-type {
            order: 0;
          }
          &:last-of-type {
            order: 1;
          }
        }
      }
      &:nth-of-type(3) {
        .about__col {
          &:first-of-type {
            order: 1;
          }
          &:last-of-type {
            order: 0;
          }
        }
      }
      &:nth-of-type(4) {
        .about__col {
          &:first-of-type {
            order: 0;
          }
          &:last-of-type {
            order: 1;
          }
        }
      }
    }
  }
}

/*320*/

@media screen and (max-width: 350px) and (-webkit-min-device-pixel-ratio: 1) {
  .about {
    &__txt {
      font-size: 15px;
    }
    &__head {
      font-size: 25px;
      margin: 22px 0 38px;
      &::after {
        bottom: -18px;
      }
    }
    &__container {
      width: 88%;
      margin: 55px auto 50px;
      .about__row {
        margin: 0 0 33px;
        flex-direction: column;
      }
    }
    &__col {
      width: 100%;
      &--img {
        align-items: flex-start;
      }
      img {
        width: 100%;
      }
    }
    &__row {
      margin: 0;
      width: 100%;
      &:first-of-type {
        .about__col {
          &:first-of-type {
            order: 1;
          }
          &:last-of-type {
            order: 0;
          }
        }
      }
      &:nth-of-type(2) {
        .about__col {
          &:first-of-type {
            order: 0;
          }
          &:last-of-type {
            order: 1;
          }
        }
      }
      &:nth-of-type(3) {
        .about__col {
          &:first-of-type {
            order: 1;
          }
          &:last-of-type {
            order: 0;
          }
        }
      }
      &:nth-of-type(4) {
        .about__col {
          &:first-of-type {
            order: 0;
          }
          &:last-of-type {
            order: 1;
          }
        }
      }
    }
  }
}
