.price {
  &__row {
    display: flex;
    width: 80%;
    margin: auto;
  }
  &__silderWrap {
    width: 100%;
    padding: 41px 0;
    position: relative;
    height: fit-content;
  }
  &__words {
    position: absolute;
    bottom: 15%;
    left: 3%;
    h1 {
      color: #fff;
      font-size: 36px;
      font-family: $font-family-base;
      font-weight: 300;
      position: relative;
      margin-bottom: 26px;
      &::after {
        content: "";
        width: 52.4px;
        height: 2.08px;
        background: #fff;
        position: absolute;
        left: 0;
        bottom: -12px;
      }
    }
    p {
      color: #fff;
      font-size: 20px;
      font-family: $font-family-base;
      font-weight: 300;
      margin-bottom: 40px;
    }
    button {
      background: #FFC400;
      height: 56px;
      width: 265px;
      color: #fff;
      font-size: 18px;
      font-family: $font-family-base;
      font-weight: 300;
      margin-right: 70px;
    }
  }
}

/*1600*/

@media screen and (min-width: 1501px) and (max-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .price {
    &__silderWrap {
      overflow: hidden;
      img {
        min-width: 1500px;
      }
    }
  }
}

/*1440*/

@media screen and (min-width: 1401px) and (max-width: 1500px) and (-webkit-min-device-pixel-ratio: 1) {
  .price {
    &__silderWrap {
      overflow: hidden;
      img {
        min-width: 1500px;
      }
    }
    &__words {
      p {
        font-size: 18px;
      }
      button {
        font-size: 16px;
      }
    }
  }
}

/*1366*/

@media screen and (min-width: 1301px) and (max-width: 1400px) and (-webkit-min-device-pixel-ratio: 1) {
  .price {
    &__silderWrap {
      overflow: hidden;
      img {
        min-width: 1500px;
      }
    }
    &__words {
      p {
        font-size: 18px;
      }
      button {
        font-size: 16px;
      }
    }
    &__row {
      width: 85%;
    }
  }
}

/*1280*/

@media screen and (min-width: 1200px) and (max-width: 1300px) and (-webkit-min-device-pixel-ratio: 1) {
  .price {
    &__silderWrap {
      overflow: hidden;
      img {
        min-width: 1500px;
      }
    }
    &__row {
      width: 92%;
    }
    &__words {
      p {
        font-size: 18px;
      }
      button {
        font-size: 16px;
      }
    }
  }
}

/*1024*/

@media screen and (min-width: 900px) and (max-width: 1199px) and (-webkit-min-device-pixel-ratio: 1) {
  .price {
    height: 640px;
    &__silderWrap {
      width: 100%;
      padding: 0;
      height: 100%;
      background: url(../images/price.png) no-repeat;
      background-size: cover;
      img {
        display: none;
      }
    }
    &__words {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      h1 {
        font-size: 50px;
        &::after {
          display: none;
        }
      }
      p {
        width: 100%;
        font-size: 16px;
      }
      button {
        margin-bottom: 30px;
        margin-right: 0;
      }
    }
    &__row {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }
}

/*800*/

@media screen and (min-width: 799px) and (max-width: 899px) and (-webkit-min-device-pixel-ratio: 1) {
  .price {
    height: 640px;
    &__silderWrap {
      width: 100%;
      padding: 0;
      height: 100%;
      background: url(../images/price.png) no-repeat;
      background-size: cover;
      img {
        display: none;
      }
    }
    &__words {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      h1 {
        font-size: 50px;
        &::after {
          display: none;
        }
      }
      p {
        width: 100%;
        font-size: 16px;
      }
      button {
        margin-bottom: 30px;
        margin-right: 0;
      }
    }
    &__row {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }
}

/*768*/

@media screen and (min-width: 601px) and (max-width: 798px) and (-webkit-min-device-pixel-ratio: 1) {
  .price {
    height: 640px;
    &__silderWrap {
      width: 100%;
      padding: 0;
      height: 100%;
      background: url(../images/price.png) no-repeat;
      background-size: cover;
      img {
        display: none;
      }
    }
    &__words {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      h1 {
        font-size: 50px;
        &::after {
          display: none;
        }
      }
      p {
        width: 100%;
        font-size: 16px;
      }
      button {
        margin-bottom: 30px;
        margin-right: 0;
      }
    }
    &__row {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }
}

/*600*/

@media screen and (min-width: 600px) and (max-width: 699px) and (-webkit-min-device-pixel-ratio: 1) {
  .price {
    height: 460px;
    &__silderWrap {
      width: 100%;
      padding: 0;
      height: 100%;
      background: url(../images/price.png) no-repeat;
      background-position: center;
      background-size: cover;
      img {
        display: none;
      }
    }
    &__words {
      left: 10%;
      top: auto;
      width: 70%;
      bottom: 5%;
      h1 {
        font-size: 28px;
      }
      p {
        width: 100%;
        font-size: 16px;
      }
      button {
        margin-bottom: 30px;
        margin-right: 0;
      }
    }
    &__row {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }
}

/*414*/

@media screen and (min-width: 410px) and (max-width: 598px) and (-webkit-min-device-pixel-ratio: 1) {
  .price {
    height: 540px;
    &__silderWrap {
      width: 100%;
      padding: 0;
      height: 100%;
      background: url(../images/price.png) no-repeat;
      background-position: center;
      background-size: cover;
      img {
        display: none;
      }
    }
    &__words {
      left: 10%;
      top: auto;
      width: 70%;
      bottom: 5%;
      h1 {
        font-size: 28px;
      }
      p {
        width: 100%;
        font-size: 16px;
      }
      button {
        margin-bottom: 30px;
        margin-right: 0;
      }
    }
    &__row {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }
}

/*384*/

@media screen and (min-width: 383px) and (max-width: 409px) and (-webkit-min-device-pixel-ratio: 1) {
  .price {
    height: 450px;
    &__silderWrap {
      width: 100%;
      padding: 0;
      height: 100%;
      background: url(../images/price.png) no-repeat;
      background-position: center;
      background-size: cover;
      img {
        display: none;
      }
    }
    &__words {
      left: 10%;
      top: auto;
      width: 70%;
      bottom: 5%;
      h1 {
        font-size: 28px;
      }
      p {
        width: 100%;
        font-size: 16px;
      }
      button {
        margin-bottom: 30px;
        margin-right: 0;
      }
    }
    &__row {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }
}

/*375*/

@media screen and (min-width: 370px) and (max-width: 382px) and (-webkit-min-device-pixel-ratio: 1) {
  .price {
    height: 450px;
    &__silderWrap {
      width: 100%;
      padding: 0;
      height: 100%;
      background: url(../images/price.png) no-repeat;
      background-position: center;
      background-size: cover;
      img {
        display: none;
      }
    }
    &__words {
      left: 10%;
      top: auto;
      width: 70%;
      bottom: 5%;
      h1 {
        font-size: 28px;
      }
      p {
        width: 100%;
        font-size: 16px;
      }
      button {
        margin-bottom: 30px;
        margin-right: 0;
      }
    }
    &__row {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }
}

/*360*/

@media screen and (min-width: 355px) and (max-width: 369px) and (-webkit-min-device-pixel-ratio: 1) {
  .price {
    height: 515px;
    &__silderWrap {
      width: 100%;
      padding: 0;
      height: 100%;
      background: url(../images/price.png) no-repeat;
      background-position: center;
      background-size: cover;
      img {
        display: none;
      }
    }
    &__words {
      left: 10%;
      top: auto;
      width: 70%;
      bottom: 5%;
      h1 {
        font-size: 28px;
      }
      p {
        width: 100%;
        font-size: 16px;
      }
      button {
        margin-bottom: 30px;
        margin-right: 0;
      }
    }
    &__row {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }
}

/*320*/

@media screen and (max-width: 350px) and (-webkit-min-device-pixel-ratio: 1) {
  .price {
    height: 515px;
    &__silderWrap {
      width: 100%;
      padding: 0;
      height: 100%;
      background: url(../images/price.png) no-repeat;
      background-position: center;
      background-size: cover;
      img {
        display: none;
      }
    }
    &__words {
      left: 10%;
      top: auto;
      width: 70%;
      bottom: 5%;
      h1 {
        font-size: 28px;
      }
      p {
        width: 100%;
        font-size: 16px;
      }
      button {
        margin-bottom: 30px;
        margin-right: 0;
      }
    }
    &__row {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }
}
