@function em($pixels, $context: $font-size-base) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }
    @if (unitless($context)) {
        $context: $context * 1px;
    }
    @return $pixels / $context * 1em;
}

body {
    font-family: $font-family-base;
    overflow-x: hidden;
}
@media only screen and (max-width: 1500px) {
    body {
        font-size: 13px;
    }
}
@media only screen and (max-width: 1200px) {
    body {
        font-size: 12px;
    }
}
@media only screen and (max-width: 992px) {
    body {
        font-size: 11px;
    }
}

::selection {
  background: #4ecbdf; /* WebKit/Blink Browsers */
  color: gray;
}

*::placeholder {
    color: gray;
}

img {
    max-width: 100%;
}

.row {
    margin: 0;
}

* {
    outline: 0;
    box-sizing: border-box;
}

button, input, textarea {
    border: none;
}

a:hover {
    text-decoration: none;
}
